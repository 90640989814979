import React, { useEffect, useState } from "react";
import ArrowIcon from '../../../images/Buy.svg'; // Adjust the path if needed

const getRandomInterval = () => Math.floor(Math.random() * 25000) + 10000; // Random interval between 10 and 35 seconds
const getRandomAmount = () => (Math.random() * 0.00089 + 0.00001).toFixed(5); // Random amount between 0.00001 and 0.0009

const getNetworkCurrency = (network) => {
  switch (network) {
    case "Base Network":
    case "Linea Network":
      return "ETH";
    case "BNB Chain":
      return "BNB";
    default:
      return "ETH"; // Default to ETH if network is not specified or recognized
  }
};

const AnimatedBanner = ({ liveSales }) => {
  const [currentMessage, setCurrentMessage] = useState("");
  const [wobble, setWobble] = useState(false);

  useEffect(() => {
    if (liveSales.length > 0) {
      const updateMessage = () => {
        const randomSale = liveSales[Math.floor(Math.random() * liveSales.length)];
        const randomAmount = getRandomAmount();
        const networkCurrency = getNetworkCurrency(randomSale.network); // Assuming network property exists in randomSale
        setCurrentMessage(`${randomAmount} ${networkCurrency} buy on $${randomSale.symbol}`);
        setWobble(true);
        setTimeout(() => setWobble(false), 2000); // End wobble after 2 seconds
      };

      const updateAtRandomIntervals = () => {
        updateMessage();
        const interval = getRandomInterval();
        return setTimeout(updateAtRandomIntervals, interval);
      };

      const timeoutId = updateAtRandomIntervals();

      return () => clearTimeout(timeoutId);
    }
  }, [liveSales]);

  return (
    <div className={`animated-banner ${wobble ? "wobble" : ""}`} style={bannerStyle}>
      <div style={innerDivStyle}>
        <img src={ArrowIcon} alt="Arrow" className="icon" style={iconStyle} />
        <div>{currentMessage}</div>
      </div>
    </div>
  );
};

const bannerStyle = {
  height: '45px', // Set height to 45px
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#000', // Adjust background color if needed
  color: '#fff', // Adjust text color if needed
  padding: '5px', // Adjust padding if needed
  borderRadius: '5px', // Adjust border radius if needed
  width: '100%', // Adjust width if needed
  maxWidth: '100%', // Ensure full width
  margin: '0 auto', // Center align
  boxSizing: 'border-box', // Include padding in element's total width and height
  paddingRight: '10px', // Add right padding
};

const innerDivStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px', // Adjust the gap between the icon and text
};

const iconStyle = {
  width: '20px', // Adjust the width of the icon
  height: '20px', // Adjust the height of the icon
};

export default AnimatedBanner;
