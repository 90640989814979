import React, { useContext, useState, useEffect } from "react";
import Context from "./context/Context";
import { ethers } from "ethers";
import Web3 from "web3";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import tokenByte from "../../bytecode/Tokens.json";
import StandardTokenABI from "../../json/StandardToken.json";
import TaxTokenABI from "../../json/TaxToken.json";
import StandardTokenZkSync from "../../json/StandardTokenZksync.json";
import Button from "react-bootstrap-button-loader";
import { contract } from "../../hooks/constant";
import { useWeb3React } from "@web3-react/core";
import { createWalletClient, custom, createPublicClient } from 'viem'
import { zkSync, zkSyncSepoliaTestnet } from 'viem/chains';
import { eip712WalletActions } from 'viem/zksync';
import { useAccount, useChainId } from "wagmi";
import { deployContract, waitForTransactionReceipt } from "@wagmi/core";
import { config } from "../../config";

export default function StandardToken(props) {
  const context = useWeb3React();
  const chainId = useChainId();
  const { address: account } = useAccount()
  const { createFee, taxCreateFee, tokenType } = props;
  const navigate = useNavigate();
  const { value, setValue } = useContext(Context);
  const [createloading, setCreateLoading] = useState(false);
  const [currentTokenType, setCurrentTokenType] = useState(tokenType);
  const [error, setError] = useState({
    name: "",
    symbol: "",
    decimals: "",
    supply: "",
    buyTax: "",
    sellTax: "",
    taxReceiver: "",
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const checkStandardValidation = (input, inputValue) => {
    let terror = 0;
    let message = "";
    var reg;
    switch (input) {
      case "name":
        if (inputValue === "") {
          terror += 1;
          message = "Please Input Token Name!";
        } else {
          message = "";
        }
        break;
      case "symbol":
        if (inputValue === "") {
          terror += 1;
          message = "Please Input Token Symbol!";
        } else {
          message = "";
        }
        break;
      case "decimals":
        inputValue = parseFloat(inputValue);
        reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
        if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Please Enter Valid Amount!";
        } else if (parseFloat(inputValue) <= 1) {
          terror += 1;
          message = "Decimals must be 2 at least!";
        } else {
          message = "";
        }
        break;
      case "supply":
        inputValue = parseFloat(inputValue);
        reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
        if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Please Enter Valid Amount!";
        } else if (parseFloat(inputValue) <= 0) {
          terror += 1;
          message = "Supply must not be zero!";
        } else {
          message = "";
        }
        break;
      case "buyTax":
      case "sellTax":
        inputValue = parseFloat(inputValue);
        if (inputValue < 0 || inputValue > 100) {
          terror += 1;
          message = "Tax must be between 0 and 100";
        } else {
          message = "";
        }
        break;
      case "taxReceiver":
        if (inputValue === "") {
          terror += 1;
          message = "Please Input Tax Receiver!";
        } else {
          message = "";
        }
        break;
      default:
        terror += 0;
        break;
    }

    if (terror > 0) {
      setError({ ...error, [input]: message });
      return false;
    } else {
      setError({ ...error, [input]: "" });
      return true;
    }
  };

  const checkStandardAllValidation = () => {
    let terror = 0;
    var reg;
    Object.keys(value).forEach((key) => {
      let inputValue;
      switch (key) {
        case "name":
          if (value[key] === "") {
            terror += 1;
          }
          break;
        case "symbol":
          if (value[key] === "") {
            terror += 1;
          }
          break;
        case "decimals":
          inputValue = parseFloat(value[key]);
          reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
          if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
            terror += 1;
          } else if (parseFloat(inputValue) <= 1) {
            terror += 1;
          }
          break;
        case "supply":
          inputValue = parseFloat(value[key]);
          reg = new RegExp(/^[+-]?\d+(\.\d+)?$/);
          if (!reg.test(inputValue) || parseFloat(inputValue) <= 0) {
            terror += 1;
          } else if (parseFloat(inputValue) <= 0) {
            terror += 1;
          }
          break;
        case "buyTax":
        case "sellTax":
          inputValue = parseFloat(value[key]);
          if (inputValue < 0 || inputValue > 100) {
            terror += 1;
          }
          break;
        case "taxReceiver":
          if (value[key] === "") {
            terror += 1;
          }
          break;
        default:
          terror += 0;
          break;
      }
      return true;
    });
    if (terror > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    checkStandardValidation(e.target.name, e.target.value);
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleCreateTaxToken = async () => {
    console.log("handleCreateTaxToken");

    try {
      setCreateLoading(true);

      // window.web3 = new Web3(window.ethereum);
      // let tokenContract = new window.web3.eth.Contract(TaxTokenABI);

      // let sendTx = {
      //   value: taxCreateFee.toString(),
      //   from: account,
      //   gasPrice: "200000000",
      //   gasLimit: 10000000,
      // };
      // if (chainId && chainId == 56 || chainId == 97) {
      //   sendTx = {
      //     value: taxCreateFee.toString(),
      //     from: account,
      //   };
      // }
      const resolveAfter3Sec = new Promise((resolve) =>
        setTimeout(resolve, 10000)
      );
      const result = await deployContract(config, {
        abi: TaxTokenABI,
        args: [
          value["name"],
          value["symbol"],
          value["decimals"],
          value["supply"] + "0".repeat(value["decimals"]),
          (Number(value["buyFee"]) * 10 ** 4).toString(),
          (Number(value["sellFee"]) * 10 ** 4).toString(),
          value["taxReceiver"],
          contract[chainId]["routeraddress"],
          contract["default"]["feeReceiver"],
          taxCreateFee.toString(),
        ],
        bytecode: tokenByte["TaxToken"],
        value: taxCreateFee.toString(),
      })
      toast.promise(resolveAfter3Sec, {
        pending: "Hold tight Ser! The crypto gods are processing your transaction! 👌",
      });
      var interval = setInterval(async function () {
        const response = await waitForTransactionReceipt(config, { hash: result });
        if (response != null) {
          clearInterval(interval);
          if (response && response.status && response.status == 'success') {
            toast.success("All hail the crypto king! Transaction approved!");
            if (typeof response.logs[0] !== "undefined") {
              navigate(
                `/token-details?addr=${response.logs[0].address}`
                // `/fairlaunch-details/${response.logs[0].address}`
              );
            } else {
              toast.error("Uh-oh, something went wrong! Ser Please try again.");
              navigate("/");
            }
          } else if (response.status === false) {
            toast.error("Yikes! Your transaction failed. Please try again, bro.");
            setCreateLoading(false);
          } else {
            toast.error("Uh-oh, something went wrong! Please try again Ser.");
            setCreateLoading(false);
          }
        }
      }, 5000);
      // await tokenContract
      //   .deploy({
      //     data: tokenByte["TaxToken"],
      //     arguments: [
      //       value["name"],
      //       value["symbol"],
      //       value["decimals"],
      //       value["supply"] + "0".repeat(value["decimals"]),
      //       (Number(value["buyFee"]) * 10 ** 4).toString(),
      //       (Number(value["sellFee"]) * 10 ** 4).toString(),
      //       value["taxReceiver"],
      //       contract[chainId]["routeraddress"],
      //       contract["default"]["feeReceiver"],
      //       taxCreateFee.toString(),
      //     ],
      //   })
      //   .send(
      //     sendTx,
      //     function (error, transactionHash) {
      //       if (transactionHash != undefined)
      //         toast.promise(resolveAfter3Sec, {
      //           pending: "Hold tight Ser! The crypto gods are processing your transaction! 👌",
      //         });
      //     }
      //   )
      //   .on("error", function (error) {
      //     toast.error("Oops! Something went wrong, ser!");
      //     console.log("error", error);
      //     setCreateLoading(false);
      //   })
      //   .on("receipt", function (receipt) {
      //     toast.success("You little degen! 🦍 Transaction confirmed! You are now officially a DEV! 🛠️");
      //     setCreateLoading(false);
      //     navigate(`/token-details?addr=${receipt.contractAddress}`);
      //   });
    } catch (err) {
      toast.error(err.reason ? err.reason : err.message);
      setCreateLoading(false);
    }

  };
  const changeTokenType = (tokenType) => {
    // e.preventDefault();
    setCurrentTokenType(tokenType);
    if (tokenType == 'Advanced') {
      navigate(`/token/Advanced`);
    } else if (tokenType == 'Standard') {
      navigate('/token/Standard');
    } else if (tokenType == 'Tax') {
      navigate('/token/Tax');
    }
  }
  const handleCreateToken = async (e) => {
    // let sendTx = {
    //   value: (currentTokenType === "Standard" ? createFee : taxCreateFee).toString(),
    //   from: account,
    //   gasPrice: "200000000",
    //   gasLimit: 5000000
    // }
    // if (chainId && (chainId === 56 || chainId === 97)) {
    //   sendTx = {
    //     value: (currentTokenType === "Standard" ? createFee : taxCreateFee).toString(),
    //     from: account
    //   }
    // }

    console.log("handleCreateToken");
    e.preventDefault();
    if (!account) {
      toast.error("Bro, connect your wallet please! 👍");
      setCreateLoading(false);
      return;
    }

    const resolveAfter3Sec = new Promise((resolve) =>
      setTimeout(resolve, 10000)
    );
    let check = checkStandardAllValidation();
    if (check) {
      if (currentTokenType === "Tax") {
        handleCreateTaxToken()
        return;
      }
      try {
        setCreateLoading(true);
        if (chainId === 324) {
          console.log("deploying to zksync")
          //deploy to zksync
          const walletClient = createWalletClient({
            chain: zkSync,
            transport: custom(window.ethereum),
          }).extend(eip712WalletActions());
          const publicClient = createPublicClient({
            chain: zkSync,
            transport: custom(window.ethereum),
          });
          const gasPrice = await publicClient.getGasPrice();
          const hash = await walletClient.deployContract({
            abi: StandardTokenZkSync,
            account,
            args: [
              value["name"],
              value["symbol"],
              value["decimals"],
              value["supply"] + "0".repeat(value["decimals"]),
              contract["default"]["feeReceiver"],
              '2000000000000'
            ],
            bytecode: tokenByte["StandardTokenZk"],
            value: 2000000000000n,
            gasPerPubdata: 500000n,
            maxFeePerGas: gasPrice,
            maxPriorityFeePerGas: gasPrice,
          });
          if (hash) {
            const { contractAddress } = await publicClient.waitForTransactionReceipt({
              hash
            });
            console.log("contract Addr", contractAddress);
            toast.success("You little Degen 🦍! Transaction approved! You are now officially a Dev! 🛠️");
            setCreateLoading(false);
            navigate(`/token-details?addr=${contractAddress}`);
          }
        } else {
          // window.web3 = new Web3(window.ethereum);
          // let tokenContract = new window.web3.eth.Contract(StandardTokenABI);
          const result = await deployContract(config, {
            abi: StandardTokenABI,
            args: [
              value["name"],
              value["symbol"],
              value["decimals"],
              value["supply"] + "0".repeat(value["decimals"]),
              contract["default"]["feeReceiver"],
              (currentTokenType === "Standard" ? createFee : taxCreateFee).toString()
            ],
            bytecode: tokenByte["StandardToken"],
            value: (currentTokenType === "Standard" ? createFee : taxCreateFee).toString()
          })
          toast.promise(resolveAfter3Sec, {
            pending: "Hold tight Ser! The crypto gods are processing your transaction! 👌",
          });
          var interval = setInterval(async function () {
            const response = await waitForTransactionReceipt(config, { hash: result });
            if (response != null) {
              clearInterval(interval);
              if (response && response.status && response.status == 'success') {
                toast.success("All hail the crypto king! Transaction approved!");
                if (typeof response.logs[0] !== "undefined") {
                  navigate(
                    `/token-details?addr=${response.logs[0].address}`
                    // `/fairlaunch-details/${response.logs[0].address}`
                  );
                } else {
                  toast.error("Uh-oh, something went wrong! Ser Please try again.");
                  navigate("/");
                }
              } else if (response.status === false) {
                toast.error("Yikes! Your transaction failed. Please try again, bro.");
                setCreateLoading(false);
              } else {
                toast.error("Uh-oh, something went wrong! Please try again Ser.");
                setCreateLoading(false);
              }
            }
          }, 5000);
          // await tokenContract
          //   .deploy({
          //     data: tokenByte["StandardToken"],
          //     arguments: [
          //       value["name"],
          //       value["symbol"],
          //       value["decimals"],
          //       value["supply"] + "0".repeat(value["decimals"]),
          //       contract["default"]["feeReceiver"],
          //       (currentTokenType === "Standard" ? createFee : taxCreateFee).toString(),
          //     ],
          //   })
          //   .send(
          //     sendTx,
          //     function (error, transactionHash) {
          //       if (transactionHash !== undefined)
          //         toast.promise(resolveAfter3Sec, {
          //           pending: "Hold tight Ser! The crypto gods are processing your transaction! 👌",
          //         });
          //     }
          //   )
          //   .on("error", function (error) {
          //     if (error.message == "The requested account and/or method has not been authorized by the user.") {
          //       toast.error("Your wallet get locked. So can't authorize! Please unlock connected wallet first!");
          //     } else {
          //       toast.error("Oops! Something went wrong, ser!");
          //     }
          //     setCreateLoading(false);
          //   })
          //   .on("receipt", function (receipt) {
          //     toast.success("All hail the crypto king! Transaction approved!");
          //     setCreateLoading(false);
          //     navigate(`/token-details?addr=${receipt.contractAddress}`);
          //   });
        }
      } catch (err) {
        console.log("catch err", err)
        if (err.message == "The requested account and/or method has not been authorized by the user.") {
          toast.error("Your wallet get locked. So can't authorize! Please unlock connected wallet first!");
        } else {
          toast.error("Oops! Something went wrong, ser!");
        }
        setCreateLoading(false);
      }
    } else {
      toast.error("Come on ser! Enter valid details!!");
      setCreateLoading(false);
    }
  };

  const containerStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    marginTop: '0px',
  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    maxWidth: '1400px',
    background: '#111432',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
    border: '1px solid #075985',
  };

  const labelStyle = {
    color: '#6EE7B7',
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  };

  const tokenTypeLabelStyle = {
    color: '#2F80ED',
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  };

  const dropdownStyle = {
    width: '90%',
    height: '50px',
    background: 'linear-gradient(162deg, #161A42 0%, rgba(22, 26, 66, 0) 100%)',
    borderRadius: '8px',
    border: '2.57px #212554 solid',
    padding: '0 10px',
    color: '#fff',
    fontSize: '16px',
    appearance: 'none',
    backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'16\' height=\'16\' viewBox=\'0 0 16 16\'%3E%3Cpath fill=\'%23ffffff\' d=\'M4 6l4 4 4-4\'/%3E%3C/svg%3E")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px center',
  };

  const createButtonStyle = {
    width: '400px', 
    height: '50px',
    background: '#2F6EFF',
    borderRadius: '8px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: '600',
    border: 'none',
    cursor: 'pointer',
    transition: 'background 0.2s',
    marginTop: '24px',
    padding: '0 32px', 
    display: 'flex',               // Set display to flex
    alignItems: 'center',          // Center vertically
    justifyContent: 'center',      // Center horizontally
    textAlign: 'center',           // Center text within the button
    margin: '0px auto 0', 
    
};


  const inputStyle = {
    width: '90%',
    height: '50px',
    background: 'linear-gradient(162deg, #161A42 0%, rgba(22, 26, 66, 0) 100%)',
    borderRadius: '8px',
    border: '2.57px #212554 solid',
    padding: '0 10px',
    color: '#fff',
    fontSize: '14px',
  };

  const descriptionStyle = {
    color: '#7B91B0',
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'left',
    marginLeft: '15px',
    marginTop: '30px',
  };

  const feeInfoContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    alignItems: 'center',
    width: '400px',
    margin: '0px auto',
    marginTop: '40px',

  };

  const feeInfoTextStyle = {
    fontSize: '0.9rem',
    color: 'white',
  };

  const hrStyle = {
    borderColor: '#212554',
    margin: '0',
  };

  // Mobile-specific styles
  const mobileContainerStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    paddingBottom: '50px', 
    marginTop: '0px',
  };

  const mobileFormStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    background: '#111432',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
    border: '1px solid #075985',
  };

  const mobileInputStyle = {
    width: '100%',
    height: '50px',
    background: 'linear-gradient(162deg, #161A42 0%, rgba(22, 26, 66, 0) 100%)',
    borderRadius: '8px',
    border: '2.57px #212554 solid',
    padding: '0 10px',
    color: '#fff',
    fontSize: '14px',
  };

  const mobileDropdownStyle = {
    width: '100%',
    height: '50px',
    background: 'linear-gradient(162deg, #161A42 0%, rgba(22, 26, 66, 0) 100%)',
    borderRadius: '8px',
    border: '2.57px #212554 solid',
    padding: '0 10px',
    color: '#fff',
    fontSize: '16px',
    appearance: 'none',
    backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'16\' height=\'16\' viewBox=\'0 0 16 16\'%3E%3Cpath fill=\'%23ffffff\' d=\'M4 6l4 4 4-4\'/%3E%3C/svg%3E")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px center',
  };

  const mobileCreateButtonStyle = {
    width: '100%',
    height: '40px',
    background: 'linear-gradient(0deg, #2F6EFF 0%, #2F6EFF 100%)',
    boxShadow: '4px 38px 62px rgba(0, 0, 0, 0.50)',
    borderRadius: '25px',
    color: '#ffffff',
    fontSize: '16px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0px auto 0',
  };

  const mobileDescriptionStyle = {
    color: '#7B91B0',
    fontSize: '12px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'left',
    marginLeft: '0px',
    marginTop: '3px'
  };

  const mobileFeeInfoContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    alignItems: 'center',
    width: '100%',
    margin: '0px auto',
  };

  const mobileFeeInfoTextStyle = {
    fontSize: '0.8rem',
    color: 'white',
  };

  return (
    <div style={isMobile ? mobileContainerStyle : containerStyle}>
      <div style={isMobile ? mobileFormStyle : formStyle}>
        <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '10px' }}>
          <div style={{ flex: '1' }}>
            <label style={tokenTypeLabelStyle}>Token Type</label>
            <select style={isMobile ? mobileDropdownStyle : dropdownStyle} value={currentTokenType} onChange={(e) => changeTokenType(e.target.value)}>
            <option value="Standard" >Basic Token</option>
              <option value="Tax">Tax Token 🔥</option>
              <option value="Advanced">Advanced Token 🔥</option>
            </select>
          </div>
          <div style={{ flex: '1', display: 'flex', alignItems: 'center', height: '100%' }}>
            <div style={isMobile ? mobileDescriptionStyle : descriptionStyle}>
              {currentTokenType === "Standard"
                ? <><span style={{ color: '#2F80ED', fontWeight: 'bold' }}>Basic Tokens</span> are secure, auto-verified token contracts that are designed for hassle-free transfers & integration with other platforms. Ideal for any project.</>
                : <><span style={{ color: '#2F80ED', fontWeight: 'bold' }}>Tax Tokens</span>  are auto-verified & designed to collect fees on every trade (buy and sell). Simply set your wallet as the Fee Receiver to start earning. </>}
            </div>
          </div>
        </div>
        <hr style={hrStyle} />
        <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '20px', flexWrap: isMobile ? 'nowrap' : 'wrap' }}>
          <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
            <label style={labelStyle}>
              Name<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              style={isMobile ? mobileInputStyle : inputStyle}
              onChange={(e) => onChangeInput(e)}
              value={value.name}
              type="text"
              name="name"
              placeholder="Eg: Degen Coin"
            />
            <small className="text-danger">{error.name}</small>
          </div>
          <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
            <label style={labelStyle}>
              Symbol<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              style={isMobile ? mobileInputStyle : inputStyle}
              onChange={(e) => onChangeInput(e)}
              value={value.symbol}
              type="text"
              name="symbol"
              placeholder="Eg: DGN"
            />
            <small className="text-danger">{error.symbol}</small>
          </div>
          <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
            <label style={labelStyle}>
              Supply<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              style={isMobile ? mobileInputStyle : inputStyle}
              onChange={(e) => onChangeInput(e)}
              value={value.supply}
              type="number"
              name="supply"
              placeholder="Eg: 1000000000"
            />
            <small className="text-danger">{error.supply}</small>
          </div>
          <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
            <label style={labelStyle}>
              Decimals<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              style={isMobile ? mobileInputStyle : inputStyle}
              onChange={(e) => onChangeInput(e)}
              value={value.decimals}
              type="number"
              name="decimals"
              placeholder="Eg: 18"
            />
            <small className="text-danger">{error.decimals}</small>
          </div>
          {currentTokenType === "Tax" && (
            <>
              <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
                <label style={labelStyle}>
                  Buy Tax (%)
                </label>
                <input
                  className="form-control"
                  style={isMobile ? mobileInputStyle : inputStyle}
                  onChange={(e) => onChangeInput(e)}
                  value={value.buyTax}
                  type="number"
                  name="buyTax"
                  placeholder="Eg: 2"
                />
                <small className="text-danger">{error.buyTax}</small>
              </div>
              <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
                <label style={labelStyle}>
                  Sell Tax (%)
                </label>
                <input
                  className="form-control"
                  style={isMobile ? mobileInputStyle : inputStyle}
                  onChange={(e) => onChangeInput(e)}
                  value={value.sellTax}
                  type="number"
                  name="sellTax"
                  placeholder="Eg: 2"
                />
                <small className="text-danger">{error.sellTax}</small>
              </div>
              <div style={{ flex: isMobile ? '1 1 100%' : '1 1 38%' }}>
                <label style={labelStyle}>
                  Tax Receiver
                </label>
                <input
                  className="form-control"
                  style={isMobile ? mobileInputStyle : inputStyle}
                  onChange={(e) => onChangeInput(e)}
                  value={value.taxReceiver}
                  type="text"
                  name="taxReceiver"
                  placeholder="Eg: 0x1234..."
                />
                <small className="text-danger">{error.taxReceiver}</small>
              </div>


            </>
          )}
        </div>
        <div style={isMobile ? mobileFeeInfoContainerStyle : feeInfoContainerStyle}>
          <div style={isMobile ? mobileFeeInfoTextStyle : feeInfoTextStyle}>Total cost:</div>
          <div style={isMobile ? mobileFeeInfoTextStyle : feeInfoTextStyle}>{(currentTokenType === "Standard" ? createFee : taxCreateFee) / 10 ** 18} {chainId && (chainId === 56 || chainId === 97) ? 'BNB' : 'ETH'}</div>
        </div>
        <div style={isMobile ? mobileCreateButtonStyle : createButtonStyle} onClick={(e) => handleCreateToken(e)}>
          CREATE TOKEN
        </div>
      </div>
    </div>
  );
}
