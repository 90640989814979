import React, { useState, useEffect } from "react";
import SprinkleImage from "../images/Sprinkle.svg"; // Adjust the path as needed

function NoticeModal({ onClose }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const hasSeenNotice = localStorage.getItem("hasSeenNotice");
    if (!hasSeenNotice) {
      setIsVisible(true);
    }

    const updateWindowDimensions = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  const handleDoNotShowAgain = () => {
    localStorage.setItem("hasSeenNotice", "true");
    setIsVisible(false);
    onClose();
  };

  if (!isVisible) return null;

  return (
    <div style={modalOverlayStyle}>
      <div style={isMobile ? mobileModalContentStyle : modalContentStyle}>
        <img src={SprinkleImage} alt="Sprinkle" style={isMobile ? mobileImageStyle : imageStyle} />
        <div style={isMobile ? mobileTextStyle : desktopTextStyle}>
          <h4 style={{ ...headerTextStyle, fontSize: isMobile ? "20px" : "24px", marginBottom: isMobile ? "5px" : "20px" }}>
            New additions:
          </h4>
          <ul style={{ 
            ...textListStyle,
            fontSize: isMobile ? "10px" : "14px",
            lineHeight: isMobile ? "1.2em" : "1.5em",
            marginBottom: isMobile ? "20px" : "50px",
            paddingLeft: isMobile ? "10px" : "20px",
          }}>
            {isMobile ? (
              <>
                <li><span style={titleStyle}>Staking:</span> Create Staking pools directly on the app.</li>
                <li><span style={titleStyle}>Portfolio:</span> View any Wallet address on multiple networks.</li>
                <li><span style={titleStyle}>Live Networks</span> Supporting Base, Linea, BNB and Solana.</li>
                <li><span style={titleStyle}>Pump Launch:</span> Create & Launch in 1 transaction for $0.15</li>
                <li><span style={titleStyle}>Degen Chat:</span> Chat with other Degens Live on the App.</li>
                <li><span style={titleStyle}>Advanced Token:</span> Auto LP, Burn, Rewards, Limits ++.</li>
                <li><span style={titleStyle}>Solana Added:</span> Create SPL tokens with logo for 0.05 SOL.</li>

              </>
            ) : (
              <>
                <li><span style={titleStyle}>Staking:</span> Create Staking pools directly on the app.</li>
                <li><span style={titleStyle}>Portfolio:</span> View any Wallet address on multiple networks.</li>
                <li><span style={titleStyle}>Pump Launch:</span> Create & Launch in 1 transaction for $0.15</li>
                <li><span style={titleStyle}>Live Networks</span> Supporting Base, Linea, BNB and Solana.</li>
                <li><span style={titleStyle}>Degen Chat:</span> Chat with other Degens Live on the App</li>
                <li><span style={titleStyle}>Advanced Token:</span> Auto LP, Burn, Rewards, Limits ++.</li>
                <li><span style={titleStyle}>Solana Added:</span> Create SPL tokens with logo for 0.05 SOL.</li>
              </>
            )}
          </ul>
        </div>

        {/* Button Section */}
        <div style={{ textAlign: "center", paddingBottom: isMobile ? "30px" : "0" }}>
          <button style={isMobile ? mobileButtonStyle : buttonStyle} onClick={handleClose}>Close</button>
          <button style={{ ...isMobile ? mobileButtonStyle : buttonStyle, marginLeft: "10px" }} onClick={handleDoNotShowAgain}>Don't show again</button>
        </div>
      </div>
    </div>
  );
}

/* Modal Overlay: Covers the entire screen when the modal is active */
const modalOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.8)", // Dark background overlay
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start", // Align to the top instead of center
  zIndex: 1000,
  paddingTop: "20px", // Add some padding at the top
};

/* Modal Content: The main container for the modal's content */
const modalContentStyle = {
  backgroundColor: "#1A1A1A", // Dark box color
  padding: "20px",
  borderRadius: "10px",
  width: "90%",
  maxWidth: "600px", // Fit nicely on desktop and mobile
  textAlign: "left",
  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.5)",
  margin: "0 auto", // Center horizontally
  maxHeight: "80vh", // Limit the height to make it scrollable
  overflowY: "auto", // Make it scrollable if content overflows
};

/* Mobile-specific Modal Content: Adjusts padding for mobile view */
const mobileModalContentStyle = {
  ...modalContentStyle,
  paddingBottom: "5px", // Extra padding at the bottom for mobile
};

/* Image Styles: Centered and resized for desktop */
const imageStyle = {
  width: "100px", // Adjust the size of the image as needed
  marginBottom: "20px", // Space below the image
  display: "block",
  marginLeft: "auto",
  marginRight: "auto", // Center the image
};

/* Mobile Image Styles: Smaller size for mobile view */
const mobileImageStyle = {
  width: "80px", // Smaller size for mobile
  marginBottom: "15px", // Adjust the space below the image for mobile
  display: "block",
  marginLeft: "auto",
  marginRight: "auto", // Center the image
};

/* Title Styles: Bold text for titles with customizable color */
const titleStyle = {
  fontWeight: "bold",
  color: "#007bff", // Blue color for the title
  fontSize: "inherit", // Inherit font size from parent (set in ul/li)
};

/* Header Text Style: Used for the "New additions" header */
const headerTextStyle = {
  color: "#fff",
  fontWeight: "bold", // Bold header text
};

/* Text List Style: General style for the list items */
const textListStyle = {
  color: "#7B91B0", // General text color
  listStyle: "none", // Remove bullet points
  paddingInlineStart: "0px", // Remove default padding
  fontSize: "12px", // Inherit font size from parent (set in ul/li)
};

/* Button Style: Base style for buttons */
const buttonStyle = {
  backgroundColor: "#007bff",
  color: "#fff",
  padding: "10px 20px",
  border: "none", // Ensure no border
  borderRadius: "5px",
  cursor: "pointer",
  lineHeight: "22px", // Ensures consistent height with text inside
  display: "inline-block", // Ensures padding applies consistently
  verticalAlign: "middle", // Ensures alignment with other buttons
  boxShadow: "none", // Remove any shadow that might be causing a grey background
};

/* Mobile Button Style: Smaller padding and margins for mobile */
const mobileButtonStyle = {
  padding: "8px 16px", // Slightly smaller padding for mobile buttons
  marginBottom: "10px", // Additional margin below each button on mobile
  lineHeight: "22px", // Ensures consistent height with text inside
  backgroundColor: "#007bff",
  color: "#fff",
  border: "none", // Ensure no border
  borderRadius: "5px",
  boxShadow: "none", // Remove any shadow that might be causing a grey background
};

const desktopTextStyle = {
  display: "block",
  fontSize: "14px", // Default desktop text size
};

/* Mobile Text Style: General text style for mobile view */
const mobileTextStyle = {
  display: "block",
  fontSize: "10x", // Smaller text size for mobile
};

export default NoticeModal;
