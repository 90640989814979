import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { base, linea, bsc, bscTestnet } from 'wagmi/chains'


const projectId = 'e163def497d4ac886a3b3423c66e9a66';

const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [base, linea, bsc, bscTestnet]
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  auth: {
    email: false
  }
})

export { config }