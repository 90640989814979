import React, { useState, useMemo, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from 'react-icons/fa';
import { Menu, MenuItem, ProSidebar, SidebarHeader, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import styled from 'styled-components';
import queryString from 'query-string';
import logo from '../images/logo_plain.png';
import logoIcon from '../images/logo.png';
import LaunchpadIcon from '../images/LaunchpadIcon.png';
import SwapIcon from '../images/SwapIcon.svg';
import BridgeIcon from '../images/BridgeIcon.svg';
import PortfolioIcon from '../images/PortfolioIcon.png';
import SocialsIcon from '../images/SocialsIcon.svg';
import CreateIcon from '../images/CreateIcon.svg';
import CreateTokenIcon from '../images/CreateTokenIcon.png';
import FairLaunchIcon from '../images/FairLaunchIcon.svg';
import BondingCurveIcon from '../images/BondingCurveIcon.svg';
import AirdropIcon from '../images/AirdropIcon.svg';
import LockIcon from '../images/LockIcon.svg';
import StakingIcon from '../images/StakingIcon.png';
import HelpVideosIcon from '../images/HelpVideosIcon.svg';
import ViewIcon from '../images/ViewIcon.svg';
import SolanaIcon from '../images/solanaicon.png';


const GoogleFontsLink = () => (
  <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet" />
);

const Icon = styled.img`
  width: ${({ size }) => size || '1.5em'};
  height: ${({ size }) => size || '1.5em'};
`;

const Menuitem = styled(MenuItem)`
  font-size: 16px;
  color: ${({ grayedOut }) => (grayedOut ? 'gray' : 'white')};
  pointer-events: ${({ grayedOut }) => (grayedOut ? 'none' : 'auto')};
  transition: background-color 0.2s ease;
  margin: 2px 0;

  .pro-inner-item {
    padding: 8px 35px 8px 20px !important;
  }
`;

const CustomSubMenu = styled(SubMenu)`
  font-size: 16px;
  color: white;
  transition: all 0.2s ease;

  .pro-inner-list-item {
    background-color: #1A1B26 !important;
    padding: 0 !important;
    border: 1px solid rgba(86, 32, 217, 0.2);
    border-radius: 10px;
    transition: all 0.2s ease;
  }
`;

const SidebarWrapper = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  color: white;
  background-color: #1A1B26;
  height: 100%;

  .pro-sidebar {
    background-color: #1A1B26;
    height: 100%;
    z-index: 1;

    .pro-sidebar-inner {
      background-color: #1A1B26;
      z-index: 1;
    }
  }

  .pro-menu {
    padding: 0;
    background-color: #1A1B26;

    > ul {
      display: flex;
      flex-direction: column;
    }
  }

  .pro-menu-item {
    .pro-inner-item {
      padding: 8px 35px 8px 20px !important;
      transition: all 0.2s ease;
    }
  }

  .pro-inner-list-item {
    background-color: #1A1B26 !important;
    padding: 0 !important;

    > div > ul {
      padding: 0 !important;
      background-color: #1A1B26 !important;
    }
  }

  .pro-sub-menu {
    position: relative;

    &.open {
      > .pro-inner-list-item {
        padding: 0 !important;
        background-color: #1A1B26 !important;
      }
    }
  }

  hr {
    margin: 10px 0;
    border-color: rgba(86, 32, 217, 0.2);
  }
`;

const MobileOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const MobileCloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 1001;
  padding: 8px;

  &:hover {
    opacity: 0.8;
  }
`;

const MobileMenuWrapper = styled.div`
  width: 320px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #1A1B26;
  overflow-y: auto;
  transform: translateX(${props => props.open ? '0' : '-100%'});
  transition: transform 0.3s ease;
`;

export function useRouter() {
  const params = useParams();
  const location = useLocation();

  return useMemo(() => {
    return {
      push: location.push,
      replace: location.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      },
      location,
    };
  }, [params, location]);
}

export default function Sidebar({ open, setOpen }) {
  const router = useRouter();
  const [width, setWidth] = useState(window.innerWidth);
  const [collapsed, setCollapsed] = useState(true);

  const isMobile = width <= 575;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const onClickMenuItem = () => {
    if (isMobile) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (isMobile && open) {
      setCollapsed(false);
    }
  }, [isMobile, open]);

  const renderContent = (
<SidebarWrapper>
  <GoogleFontsLink />
  <ProSidebar collapsed={collapsed}>
    <SidebarHeader>
      <div style={{ display: 'flex', alignItems: 'center', padding: '8px', position: 'relative' }}>
        <img src={collapsed ? logoIcon : logo} alt="Brand Logo" height="40px" />
      </div>
    </SidebarHeader>
    <Menu iconShape="" style={{ background: 'none' }}>
      <Menuitem
        icon={<Icon src={ViewIcon} style={{ width: '27px', height: '27px' }} />}
        style={{ background: 'rgba(86, 32, 217, 0.1)', cursor: 'default' }}
      >
        View
      </Menuitem>

      <Menuitem
        icon={<Icon src={LaunchpadIcon} />}
        className={`${router.pathname === "/" ? "active" : ""}`}
      >
        <Link to="/" onClick={onClickMenuItem} style={{ color: 'white' }}>Launchpad</Link>
      </Menuitem>

      <Menuitem
        icon={<Icon src={SolanaIcon} />}
        onClick={onClickMenuItem}
      >
        <a href="https://solana.defilaunch.app/" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>Sol Launch</a>
      </Menuitem>

      <Menuitem
        icon={<Icon src={PortfolioIcon} />}
        className={`${router.pathname === "/wallet" ? "active" : ""}`}
      >
        <Link to="/wallet" onClick={onClickMenuItem} style={{ color: 'white' }}>Portfolio</Link>
      </Menuitem>

      {/* Socials Menu - Clicking expands sidebar if collapsed */}
      <SubMenu
        title="Socials"
        icon={<Icon src={SocialsIcon} />}
        className={`${router.pathname.startsWith("/socials") ? "active" : ""}`}
        onClick={() => {
          if (collapsed) {
            setCollapsed(false); // Expand the sidebar when clicked if collapsed
          }
        }}
      >
        {/* Render submenu items only when sidebar is expanded */}
        {!collapsed && (
          <>
            <MenuItem onClick={onClickMenuItem}>
              <a href="https://discord.com/invite/TsvvJUS58m" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>Discord</a>
            </MenuItem>
            <MenuItem onClick={onClickMenuItem}>
              <a href="https://twitter.com/defilaunchapp" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>Twitter</a>
            </MenuItem>
            <MenuItem onClick={onClickMenuItem}>
              <a href="https://t.me/defilaunchapp" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>Telegram</a>
            </MenuItem>
            <MenuItem onClick={onClickMenuItem}>
              <a href="https://warpcast.com/~/invite-page/398484?id=e17eaba6" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>Warpcast</a>
            </MenuItem>
          </>
        )}
      </SubMenu>

      <hr style={{ border: '0px solid #075985', margin: '10px 0' }} />

      <Menuitem
        icon={<Icon src={CreateIcon} style={{ width: '32px', height: '32px' }} />}
        style={{ background: 'rgba(86, 32, 217, 0.1)', cursor: 'default' }}
      >
        Create
      </Menuitem>

      <Menuitem
        icon={<Icon src={CreateTokenIcon} />}
        className={`${router.pathname === "/token" ? "active" : ""}`}
      >
        <Link to="/token/Tax" onClick={onClickMenuItem} style={{ color: 'white' }}>Meme Coin 🔥</Link>
      </Menuitem>

      <Menuitem
        icon={<Icon src={BondingCurveIcon} />}
        className={`${router.pathname === "/bonding-token-sale" ? "active" : ""}`}
      >
        <Link to="/bonding-token-sale" onClick={onClickMenuItem} style={{ color: 'white' }}>Pump Launch</Link>
      </Menuitem>

      <Menuitem
        icon={<Icon src={FairLaunchIcon} />}
        className={`${router.pathname === "/fairlaunch" ? "active" : ""}`}
      >
        <Link to="/fairlaunch" onClick={onClickMenuItem} style={{ color: 'white' }}>Fair Launch</Link>
      </Menuitem>

      <Menuitem
        icon={<Icon src={AirdropIcon} />}
        className={`${router.pathname === "/airdrop-tokens" ? "active" : ""}`}
      >
        <Link to="/airdrop-tokens" onClick={onClickMenuItem} style={{ color: 'white' }}>Airdrop</Link>
      </Menuitem>

      {/* Lock Menu - Clicking expands sidebar if collapsed */}
      <SubMenu
        title="Lock"
        icon={<Icon src={LockIcon} />}
        className={`${router.pathname.startsWith("/lock") ? "active" : ""}`}
        onClick={() => {
          if (collapsed) {
            setCollapsed(false); // Expand the sidebar when clicked if collapsed
          }
        }}
      >
        {/* Render submenu items only when sidebar is expanded */}
        {!collapsed && (
          <>
            <MenuItem onClick={onClickMenuItem}>
              <Link to="/lock" style={{ color: 'white' }}>Create</Link>
            </MenuItem>
            <MenuItem onClick={onClickMenuItem}>
              <Link to="/token-locked" style={{ color: 'white' }}>Token List</Link>
            </MenuItem>
            <MenuItem onClick={onClickMenuItem}>
              <Link to="/liquidity-locked" style={{ color: 'white' }}>LP List</Link>
            </MenuItem>
          </>
        )}
      </SubMenu>

      <Menuitem
        icon={<Icon src={StakingIcon} />}
        className={`${router.pathname === "/staking" ? "active" : ""}`}
      >
        <Link to="/staking" onClick={onClickMenuItem} style={{ color: 'white' }}>Staking</Link>
      </Menuitem>

      <hr style={{ border: '0.5px solid #075985', margin: '10px 0' }} />

      <Menuitem
        icon={<Icon src={HelpVideosIcon} />}
        onClick={onClickMenuItem}
      >
        <a href="https://www.youtube.com/channel/UC6khIxCjTEAQc_YqYpMxTVg" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>Help Videos</a>
      </Menuitem>
    </Menu>
  </ProSidebar>
</SidebarWrapper>



  );

  return (
    <>
      {!isMobile && (
        <div 
          style={{ 
            position: 'fixed', 
            top: '99px',
            left: collapsed ? '65px' : '220px',
            cursor: 'pointer', 
            zIndex: 99999999,
            padding: '5px',
            borderRadius: '5px',
            width: '24px',
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'left 0.0s ease'
          }} 
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? <FaAngleRight size={16} /> : <FaAngleLeft size={16} />}
        </div>
      )}
      
      {isMobile ? (
        <>
          {open && <MobileOverlay onClick={() => setOpen(false)} />}
          <MobileMenuWrapper open={open}>
            {open && (
              <MobileCloseButton onClick={() => setOpen(false)}>
                <FaTimes size={24} />
              </MobileCloseButton>
            )}
            {renderContent}
          </MobileMenuWrapper>
        </>
      ) : (
        renderContent
      )}
    </>
  );
  }
           
      