import React from 'react';
import { useNavigate } from 'react-router-dom';
import StakingPoolCard from './StakingPoolCard'; // Import StakingPoolCard component

// Mock data for staking pools
const stakingPools = [
    {
      id: 1,
      name: 'HOLD',
      tokenSymbol: 'EARN',
      totalStaked: '900,993,959 EARN',
      apy: '0 %',
      hardCap: '100M',
      totalStakers: '8',
      liveStatus: true,
      timeRemaining: '13:00:58',
      logoUrl: 'https://i.postimg.cc/63NmBk4q/template.jpg',  // Direct URL to project logo
      bannerUrl: 'https://i.postimg.cc/2554h2pG/template-banner.jpg', // Direct URL to project banner
    },
    {
      id: 2,
      name: 'Mecat Staking #01',
      tokenSymbol: 'MECAT',
      totalStaked: '594,039,973 MECAT',
      apy: '200 %',
      hardCap: '5M',
      totalStakers: '594K',
      liveStatus: true,
      timeRemaining: '3:06:20:58',
      logoUrl: 'https://i.postimg.cc/63NmBk4q/template.jpg',  // Direct URL to project logo
      bannerUrl: 'https://i.postimg.cc/2554h2pG/template-banner.jpg', // Direct URL to project banner
    },
  // Additional mock pool data can be added here...
];

const StakingPoolsList = () => {
  const navigate = useNavigate();

  const handleCreatePool = () => {
    // Logic to open a create pool form or navigate to a create page
    navigate('/create-pool'); // Redirects to the Create Pool page
  };

  return (
    <div
      style={{
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#0A0B1E',
        color: '#FFFFFF',
        padding: '20px',
        fontFamily: "'Quicksand', sans-serif",
      }}
    >
      {/* Header Section */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <h1 style={{ fontSize: '2rem', fontWeight: 'bold' }}>Staking Pools</h1>
        <button
          style={{
            padding: '10px 20px',
            backgroundColor: '#3461FF',
            color: '#FFFFFF',
            fontSize: '1rem',
            fontWeight: '500',
            borderRadius: '8px',
            cursor: 'pointer',
            border: 'none',
          }}
          onClick={handleCreatePool}
        >
          Create Pool
        </button>
      </div>

      {/* Staking Pools Grid */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: '20px',
        }}
      >
        {stakingPools.map((pool) => (
          <StakingPoolCard key={pool.id} pool={pool} />
        ))}
      </div>
    </div>
  );
};

export default StakingPoolsList;
