import { useEffect, useState, useRef, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import poolManagerAbi from "../../../../json/poolManager.json";
import { MulticallContractWeb3 } from "../../../../hooks/useContracts";
import { getWeb3 } from "../../../../hooks/connectors";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { contract } from "../../../../hooks/constant";
import { supportNetwork } from "../../../../hooks/network";
import presalePoolAbi from "../../../../json/presalePool.json";
import { currencies } from "../../../../hooks/currencies";
import { SiAral } from "react-icons/si";
import { linea } from "viem/chains";
import { useAccount, useChainId } from "wagmi";

export const usePoolListStats = (updater) => {
  const syncRef = useRef(true)
  let { page, pageSize, loading } = updater;
  const context = useWeb3React();
  const chainId = useChainId();
  const { address: account } = useAccount();
  let navigate = useNavigate();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get("chainid");
  let web3 = getWeb3(queryChainId ? queryChainId : chainId);
  let poolManagerAddress = contract[queryChainId ? queryChainId : chainId]
    ? contract[queryChainId ? queryChainId : chainId].poolmanager
    : contract["default"].poolmanager;
  useEffect(() => {
    poolManagerAddress = contract[queryChainId ? queryChainId : chainId]
      ? contract[queryChainId ? queryChainId : chainId].poolmanager
      : contract["default"].poolmanager;
  }, [chainId, queryChainId])

  let poolManagerContract = new web3.eth.Contract(
    poolManagerAbi,
    poolManagerAddress
  );
  // account not connected part
  let baseWeb3 = getWeb3(8453);
  let lineaWeb3 = getWeb3(59144);
  let bnbWeb3 = getWeb3(56);
  let poolManagerAddressBase = contract['8453'].poolmanager;
  let poolManagerAddressLinea = contract['59144'].poolmanager;
  let poolManagerAddressBnb = contract['56'].poolmanager;
  let poolManagerContractBase = new baseWeb3.eth.Contract(
    poolManagerAbi, poolManagerAddressBase
  );
  let poolManagerContractLinea = new lineaWeb3.eth.Contract(
    poolManagerAbi, poolManagerAddressLinea
  )
  let poolManagercontractBnb = new bnbWeb3.eth.Contract(
    poolManagerAbi, poolManagerAddressBnb
  )
  const getCurrencyList = (currencies) => {
    let currencyList = [];
    currencies.map((currency, index) => {
      currencyList[currency.address] = currency.symbol;
    });
    return currencyList;
  };

  let currencyList = getCurrencyList(
    currencies[chainId] !== undefined
      ? currencies[chainId]
      : currencies["default"]
  );

  const [stats, setStats] = useState({
    getTotalNumberOfPools: 0,
    page: page,
    pageSize: pageSize,
    poolList: [],
    loading: true,
    chainId: supportNetwork[queryChainId ? queryChainId : chainId]
      ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
      : supportNetwork["default"].chainId,
  });
  const [bondingStats, setBondingStats] = useState({
    getTotalNumberOfPools: 0,
    page: page,
    pageSize: pageSize,
    poolList: [],
    loading: true,
    chainId: supportNetwork[queryChainId ? queryChainId : chainId]
      ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
      : supportNetwork["default"].chainId,
  });

  const mc = MulticallContractWeb3(queryChainId ? queryChainId : chainId);
  const baseMc = MulticallContractWeb3(8453);
  const lineaMc = MulticallContractWeb3(59144);
  const bnbMc = MulticallContractWeb3(56);
  const fetchBondingAll = useCallback(async () => {
    console.log("Starting fetchBondingAll");
    try {
      // Get Base Network Data
      const baseData = await baseMc.aggregate([
        poolManagerContractBase.methods.getTotalNumberOfBondingPools(),
      ]);
      console.log("Base Bonding Pool Count:", baseData[0]);
      let basePoolData = [];
      if (baseData[0] > 0) {
        console.log("Fetching base pool data");
        const result = await baseMc.aggregate([poolManagerContractBase.methods.getCumulativeBondingInfo()]);
        console.log("Base pool result:", result);
        if (result && result[0]) {
          basePoolData = result[0];
        }
      }
  
      // Get Linea Network Data
      const lineaData = await lineaMc.aggregate([
        poolManagerContractLinea.methods.getTotalNumberOfBondingPools(),
      ]);
      let lineaPoolData = [];
      if (lineaData[0] > 0) {
        const result = await lineaMc.aggregate([poolManagerContractLinea.methods.getCumulativeBondingInfo()]);
        if (result && result[0]) {
          lineaPoolData = result[0];
        }
      }
  
      // Get BNB Network Data
      const bnbData = await bnbMc.aggregate([
        poolManagercontractBnb.methods.getTotalNumberOfBondingPools(),
      ]);
      let bnbPoolData = [];
      if (bnbData[0] > 0) {
        const result = await bnbMc.aggregate([poolManagercontractBnb.methods.getCumulativeBondingInfo()]);
        if (result && result[0]) {
          bnbPoolData = result[0];
        }
      }
  
      let poolLists = basePoolData.concat(lineaPoolData).concat(bnbPoolData);
      console.log("Combined pool lists:", poolLists);
  
      // Process and set the data
      if (poolLists.length > 0 && syncRef.current) {
        let poolList = poolLists.map((value) => {
          let x = {
            kyc: value.kycStatus,
            audit: value.auditStatus,
            decimals: value.decimals,
            name: value.name,
            symbol: value.symbol,
            poolAddress: value.poolAddress,
            poolState: value.poolState,
            poolDetails: value.poolDetails,
            poolType: value.poolType,
            token: value.token,
            logourl: value.poolDetails ? value.poolDetails.toString().split("$#$")[0] : '',
            bannerurl: value.poolDetails ? value.poolDetails.toString().split("$#$")[1] : '',
            tokenId: value.tokenId,
            holders: value._holders,
            tokenPrice: value.tokenPrice,
            tokenTotalSupply: value.tokenTotalSupply,
            circulatingSupply: value.circulatingSupply,
            currentMK: Number(value.circulatingSupply) / (10 ** 36) * Number(value.tokenPrice)
          }
          return x;
        });
        poolList.sort((a, b) => a.poolState - b.poolState);
        setBondingStats({
          getTotalNumberOfPools: baseData[0] + lineaData[0] + bnbData[0],
          page: page,
          pageSize: pageSize,
          poolList: poolList,
          loading: false,
          chainId: supportNetwork[queryChainId ? queryChainId : chainId]
            ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
            : supportNetwork["default"].chainId,
        });
      } else {
        setBondingStats({
          getTotalNumberOfPools: 0,
          page: page,
          pageSize: pageSize,
          poolList: [],
          loading: false,
          chainId: supportNetwork[queryChainId ? queryChainId : chainId]
            ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
            : supportNetwork["default"].chainId,
        });
      }
    } catch (e) {
      console.error("Error in fetchBondingAll:", e);
      setBondingStats({
        getTotalNumberOfPools: 0,
        page: page,
        pageSize: pageSize,
        poolList: [],
        loading: false,
        chainId: supportNetwork[queryChainId ? queryChainId : chainId]
          ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
          : supportNetwork["default"].chainId,
      });
    }
  }, [bondingStats, mc]);
  const fetchAll = useCallback(async () => {
    const baseData = await baseMc.aggregate([
      poolManagerContractBase.methods.getTotalNumberOfPools(),
    ]);
    let basePoolData = [];
    if (baseData[0] > 0) {
      const result = await baseMc.aggregate([poolManagerContractBase.methods.getCumulativePoolInfo(0, baseData[0])]);
      basePoolData = result[0];
    }
    const lineaData = await lineaMc.aggregate([
      poolManagerContractLinea.methods.getTotalNumberOfPools(),
    ]);
    let lineaPoolData = [];
    if (lineaData[0] > 0) {
      const result = await lineaMc.aggregate([poolManagerContractLinea.methods.getCumulativePoolInfo(0, lineaData[0])]);
      lineaPoolData = result[0];
    }
    const bnbData = await bnbMc.aggregate([
      poolManagercontractBnb.methods.getTotalNumberOfPools(),
    ]);
    let bnbPoolData = [];
    if (bnbData[0] > 0) {
      const result = await bnbMc.aggregate([poolManagercontractBnb.methods.getCumulativePoolInfo(0, bnbData[0])]);
      bnbPoolData = result[0];
    }

    let poolLists = basePoolData.concat(lineaPoolData).concat(bnbPoolData);
    poolLists.sort((a, b) => a.totalRaised - b.totalRaised);
    if (poolLists.length == 0 && syncRef.current) {
      setStats({
        getTotalNumberOfPools: 0,
        page: page,
        pageSize: pageSize,
        poolList: [],
        loading: false,
        chainId: supportNetwork[queryChainId ? queryChainId : chainId]
          ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
          : supportNetwork["default"].chainId,
      });
    } else if (syncRef.current) {
      let poolList = poolLists.map((value) => {
        let status = "";
        if (value.poolState === "1") {
          status = "completed";
        } else if (value.poolState === "2") {
          status = "canceled";
        } else if (
          parseInt(value.endTime) < Math.floor(new Date().getTime() / 1000.0)
        ) {
          status = "ended";
        } else if (
          parseInt(value.startTime) > Math.floor(new Date().getTime() / 1000.0)
        ) {
          status = "upcoming";
        } else if (
          parseInt(value.startTime) < Math.floor(new Date().getTime() / 1000.0) &&
          parseInt(value.endTime) > Math.floor(new Date().getTime() / 1000.0)
        ) {
          status = "active";
        }

        let x = {
          kyc: value[9],
          audit: value[14],
          decimals: value.decimals,
          name: value.name,
          symbol: value.symbol,
          poolAddress: value.poolAddress,
          currencyAddress: value.currency,
          currencySymbol: currencyList[value.currency.toLowerCase()],
          endTime: value.endTime,
          hardCap: value.hardCap / Math.pow(10, 18),
          liquidityListingRate: value.liquidityListingRate / Math.pow(10, value.decimals),
          liquidityPercent: value.liquidityPercent,
          maxContribution: value.maxContribution / Math.pow(10, 18),
          minContribution: value.minContribution / Math.pow(10, 18),
          poolState: value.poolState,
          poolDetails: value.poolDetails,
          poolType: value.poolType,
          rate: value.rate / Math.pow(10, value.decimals),
          softCap: value.softCap / Math.pow(10, 18),
          startTime: value.startTime,
          token: value.token,
          totalRaised: value.totalRaised / Math.pow(10, 18),
          percentageRaise:
            (value.totalRaised /
              Math.pow(10, 18) /
              (value.poolType === "2"
                ? value.softCap / Math.pow(10, 18)
                : value.hardCap / Math.pow(10, 18))) *
            100,
          logourl: value.poolDetails.toString().split("$#$")[0],
          bannerurl: value.poolDetails.toString().split("$#$")[1],
          status,
          routerVersion: value.routerVersion
        }
        return x;
      });
      poolList.sort((a, b) => b.status.localeCompare(a.status) || a.totalRaised - b.totalRaised);
      setStats({
        getTotalNumberOfPools: baseData[0] + lineaData[0] + bnbData[0],
        poolList: poolList.slice(),
        page: page,
        pageSize: pageSize,
        loading: !loading,
        chainId: supportNetwork[queryChainId ? queryChainId : chainId]
          ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
          : supportNetwork["default"].chainId,
      });
    }
  }, [stats, mc])
  const fetchBonding = useCallback(async () => {
    // try {
    const data = await mc.aggregate([poolManagerContract.methods.getTotalNumberOfBondingPools(),]);
    console.log("datasdd", data)
    if (data[0] > 0) {
      let poolData = await mc.aggregate([
        poolManagerContract.methods.getCumulativeBondingInfo(),
      ]);
      console.log("data", poolData);
      let poolList = poolData[0].map((value) => {
        let x = {
          kyc: value.kycStatus,
          audit: value.auditStatus,
          decimals: value.decimals,
          name: value.name,
          symbol: value.symbol,
          poolAddress: value.poolAddress,
          poolState: value.poolState,
          poolDetails: value.poolDetails,
          poolType: value.poolType,
          token: value.token,
          logourl: value.poolDetails.toString().split("$#$")[0],
          bannerurl: value.poolDetails.toString().split("$#$")[1],
          tokenId: value.tokenId,
          holders: value._holders,
          tokenPrice: value.tokenPrice,
          tokenTotalSupply: value.tokenTotalSupply,
          circulatingSupply: value.circulatingSupply,
          currentMK: Number(value.circulatingSupply) / (10 ** 36) * Number(value.tokenPrice)
        }
        return x;
      });
      poolList.sort((a, b) => a.poolState - b.poolState);
      setBondingStats({
        getTotalNumberOfPools: data[0],
        page: page,
        pageSize: pageSize,
        poolList: poolList,
        loading: false,
        chainId: supportNetwork[queryChainId ? queryChainId : chainId]
          ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
          : supportNetwork["default"].chainId,
      })
    } else {
      setBondingStats({
        getTotalNumberOfPools: 0,
        page: page,
        pageSize: pageSize,
        poolList: [],
        loading: false,
        chainId: supportNetwork[queryChainId ? queryChainId : chainId]
          ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
          : supportNetwork["default"].chainId,
      })
    }

    // } catch(e) {
    //   console.log("err", e);
    //   setBondingStats({
    //     getTotalNumberOfPools: 0,
    //     page: page,
    //     pageSize: pageSize,
    //     poolList: [],
    //     loading: false,
    //     chainId: supportNetwork[queryChainId ? queryChainId : chainId]
    //     ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
    //     : supportNetwork["default"].chainId,
    //   })
    // }

  }, [bondingStats, mc, chainId])
  const fetch = useCallback(async () => {
    try {
      const data = await mc.aggregate([
        poolManagerContract.methods.getTotalNumberOfPools(),
      ]);

      if (data[0] > 0 && !syncRef.current) {
        //pagination part
        // let start = data[0] - 1 - page * pageSize - (pageSize - 1);
        // let end = start + pageSize - 1;
        let poolData = await mc.aggregate([
          poolManagerContract.methods.getCumulativePoolInfo(
            0, data[0]
            // start >= 0 ? start : 0,
            // end < data[0] ? end : data[0]
          ),
        ]);
        let poolList = poolData[0].map((value) => {
          let status = "";
          if (value.poolState === "1") {
            status = "completed";
          } else if (value.poolState === "2") {
            status = "canceled";
          } else if (
            parseInt(value.endTime) < Math.floor(new Date().getTime() / 1000.0)
          ) {
            status = "ended";
          } else if (
            parseInt(value.startTime) > Math.floor(new Date().getTime() / 1000.0)
          ) {
            status = "upcoming";
          } else if (
            parseInt(value.startTime) < Math.floor(new Date().getTime() / 1000.0) &&
            parseInt(value.endTime) > Math.floor(new Date().getTime() / 1000.0)
          ) {
            status = "active";
          }
          let x = {
            kyc: value[9],
            audit: value[14],
            decimals: value.decimals,
            name: value.name,
            symbol: value.symbol,
            poolAddress: value.poolAddress,
            currencyAddress: value.currency,
            currencySymbol: currencyList[value.currency.toLowerCase()],
            endTime: value.endTime,
            hardCap: value.hardCap / Math.pow(10, 18),
            liquidityListingRate:
              value.liquidityListingRate / Math.pow(10, value.decimals),
            liquidityPercent: value.liquidityPercent,
            maxContribution: value.maxContribution / Math.pow(10, 18),
            minContribution: value.minContribution / Math.pow(10, 18),
            poolState: value.poolState,
            poolDetails: value.poolDetails,
            poolType: value.poolType,
            rate: value.rate / Math.pow(10, value.decimals),
            softCap: value.softCap / Math.pow(10, 18),
            startTime: value.startTime,
            token: value.token,
            totalRaised: value.totalRaised / Math.pow(10, 18),
            percentageRaise:
              (value.totalRaised /
                Math.pow(10, 18) /
                (value.poolType === "2"
                  ? value.softCap / Math.pow(10, 18)
                  : value.hardCap / Math.pow(10, 18))) *
              100,
            logourl: value.poolDetails.toString().split("$#$")[0],
            bannerurl: value.poolDetails.toString().split("$#$")[1],
            status,
            routerVersion: value.routerVersion,
            tokenId: value.tokenId
          }
          return x;
        });
        poolList.sort((a, b) => b.status.localeCompare(a.status) || a.totalRaised - b.totalRaised);
        const objectResult = {
          getTotalNumberOfPools: data[0] - 1,
          poolList: poolList.slice(),
          page: page,
          pageSize: pageSize,
          loading: !loading,
          chainId: supportNetwork[queryChainId ? queryChainId : chainId]
            ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
            : supportNetwork["default"].chainId,
        }
        setStats(objectResult);
      } else if (!syncRef.current) {
        setStats({
          getTotalNumberOfPools: 0,
          page: page,
          pageSize: pageSize,
          poolList: [],
          loading: false,
          chainId: supportNetwork[queryChainId ? queryChainId : chainId]
            ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
            : supportNetwork["default"].chainId,
        });
      }
    } catch (err) {
      toast.error(err.reason);
      navigate("/");
    }
  }, [stats, mc, chainId]);

  useEffect(() => {
    if (mc) {
      if (account) {
        syncRef.current = false
        fetchBonding();
        fetch();
      }
      else {
        syncRef.current = true;
        fetchBondingAll();
        fetchAll();

      }
    } else {
      setStats({
        getTotalNumberOfPools: 0,
        page: page,
        pageSize: pageSize,
        poolList: [],
        loading: false,
        chainId: supportNetwork[queryChainId ? queryChainId : chainId]
          ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
          : supportNetwork["default"].chainId,
      });
    }
    // eslint-disable-next-line
  }, [account, updater, chainId]);

  return [stats, bondingStats];
};

export const usePoolListUser = (updater) => {
  let { page, pageSize, loading } = updater;
  const context = useWeb3React();
  const chainId = useChainId();
  const { address: account } = useAccount()
  let navigate = useNavigate();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get("chainid");

  let web3 = getWeb3(queryChainId ? queryChainId : chainId);
  let poolManagerAddress = contract[queryChainId ? queryChainId : chainId]
    ? contract[queryChainId ? queryChainId : chainId].poolmanager
    : contract["default"].poolmanager;
  let poolManagerContract = new web3.eth.Contract(
    poolManagerAbi,
    poolManagerAddress
  );

  const getCurrencyList = (currencies) => {
    let currencyList = [];
    currencies.map((currency, index) => {
      currencyList[currency.address] = currency.symbol;
    });
    return currencyList;
  };

  let currencyList = getCurrencyList(
    currencies[chainId] !== undefined
      ? currencies[chainId]
      : currencies["default"]
  );

  const [stats, setStats] = useState({
    getTotalNumberOfPools: 0,
    page: page,
    pageSize: pageSize,
    poolList: [],
    loading: true,
    chainId: supportNetwork[queryChainId ? queryChainId : chainId]
      ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
      : supportNetwork["default"].chainId,
  });

  const mc = MulticallContractWeb3(queryChainId ? queryChainId : chainId);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await mc.aggregate([
          poolManagerContract.methods.getTotalNumberOfContributedPools(account),
        ]);

        if (data[0] > 0) {
          let start = data[0] - 1 - page * pageSize - (pageSize - 1);
          let end = start + pageSize - 1;

          const poolData = await mc.aggregate([
            poolManagerContract.methods.getUserContributedPoolInfo(
              account,
              start >= 0 ? start : 0,
              end < data[0] ? end : data[0]
            ),
          ]);
          let poolList = poolData[0].map((value) => {
            let status = "";
            if (value.poolState === "1") {
              status = "completed";
            } else if (value.poolState === "2") {
              status = "canceled";
            } else if (
              parseInt(value.endTime) < Math.floor(new Date().getTime() / 1000.0)
            ) {
              status = "ended";
            } else if (
              parseInt(value.startTime) > Math.floor(new Date().getTime() / 1000.0)
            ) {
              status = "upcoming";
            } else if (
              parseInt(value.startTime) < Math.floor(new Date().getTime() / 1000.0) &&
              parseInt(value.endTime) > Math.floor(new Date().getTime() / 1000.0)
            ) {
              status = "active";
            }
            let x = {
              kyc: value[9],
              audit: value[14],
              decimals: value.decimals,
              name: value.name,
              symbol: value.symbol,
              poolAddress: value.poolAddress,
              currencyAddress: value.currency,
              currencySymbol: currencyList[value.currency.toLowerCase()],
              endTime: value.endTime,
              hardCap: value.hardCap / Math.pow(10, 18),
              liquidityListingRate:
                value.liquidityListingRate / Math.pow(10, value.decimals),
              liquidityPercent: value.liquidityPercent,
              maxContribution: value.maxContribution / Math.pow(10, 18),
              minContribution: value.minContribution / Math.pow(10, 18),
              poolState: value.poolState,
              poolDetails: value.poolDetails,
              poolType: value.poolType,
              rate: value.rate / Math.pow(10, value.decimals),
              softCap: value.softCap / Math.pow(10, 18),
              startTime: value.startTime,
              token: value.token,
              totalRaised: value.totalRaised / Math.pow(10, 18),
              percentageRaise:
                (value.totalRaised /
                  Math.pow(10, 18) /
                  (value.poolType === "2"
                    ? value.softCap / Math.pow(10, 18)
                    : value.hardCap / Math.pow(10, 18))) *
                100,
              logourl: value.poolDetails.toString().split("$#$")[0],
              bannerurl: value.poolDetails.toString().split("$#$")[1],
              status,
              routerVersion: value.routerVersion,
              tokenId: value.tokenId
            }
            return x;
          });
          poolList.sort((a, b) => b.status.localeCompare(a.status) || a.totalRaised - b.totalRaised);
          const objectResult = {
            getTotalNumberOfPools: data[0] - 1,
            poolList: poolList.slice(),
            page: page,
            pageSize: pageSize,
            loading: !loading,
            chainId: supportNetwork[queryChainId ? queryChainId : chainId]
              ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
              : supportNetwork["default"].chainId,
          }
          setStats(objectResult);
        } else {
          setStats({
            getTotalNumberOfPools: 0,
            page: page,
            pageSize: pageSize,
            poolList: [],
            loading: false,
            chainId: supportNetwork[queryChainId ? queryChainId : chainId]
              ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
              : supportNetwork["default"].chainId,
          });
        }
      } catch (err) {
        toast.error(err.reason);
        navigate("/");
      }
    };

    if (mc && account && chainId) {
      fetch();
    } else {
      setStats({
        getTotalNumberOfPools: 0,
        page: page,
        pageSize: pageSize,
        poolList: [],
        loading: false,
        chainId: supportNetwork[queryChainId ? queryChainId : chainId]
          ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
          : supportNetwork["default"].chainId,
      });
    }
    // eslint-disable-next-line
  }, [account, updater, chainId]);

  return stats;
};

export const useTopPoolState = (updater) => {
  const context = useWeb3React();
  const chainId = useChainId();
  let web3 = getWeb3(chainId);
  let poolManagerAddress = contract[chainId]
    ? contract[chainId].poolmanager
    : contract["default"].poolmanager;
  let poolManagerContract = new web3.eth.Contract(
    poolManagerAbi,
    poolManagerAddress
  );

  const [stats, setStats] = useState({
    topPools: [],
    chainId: supportNetwork[chainId]
      ? supportNetwork[chainId].chainId
      : supportNetwork["default"].chainId,
  });

  const getCurrencyList = (currencies) => {
    let currencyList = [];
    currencies.map((currency, index) => {
      currencyList[currency.address] = currency.symbol;
    });
    return currencyList;
  };

  let currencyList = getCurrencyList(
    currencies[chainId] !== undefined
      ? currencies[chainId]
      : currencies["default"]
  );

  const mc = MulticallContractWeb3(chainId);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await mc.aggregate([
          poolManagerContract.methods.getTopPool(),
        ]);

        let res = [];

        for (let i = 0; i < data[0].length; i++) {
          if (
            data[0][i]["poolAddress"] !==
            "0x0000000000000000000000000000000000000000"
          ) {
            let poolContract = new web3.eth.Contract(
              presalePoolAbi,
              data[0][i]["poolAddress"]
            );
            const poolInfo = await mc.aggregate([
              poolContract.methods.getPoolInfo(),
            ]);
            res.push({
              poolAddress: data[0][i]["poolAddress"],
              poolInfo: poolInfo[0],
            });
          }
        }

        setStats({
          topPools: res,
          chainId: supportNetwork[chainId]
            ? supportNetwork[chainId].chainId
            : supportNetwork["default"].chainId,
        });
      } catch (err) {
        toast.error(err.reason);
      }
    };

    if (mc) {
      fetch();
    } else {
      setStats({
        topPools: [],
        chainId: supportNetwork[chainId]
          ? supportNetwork[chainId].chainId
          : supportNetwork["default"].chainId,
      });
    }
    // eslint-disable-next-line
  }, [updater, chainId]);

  return stats;
};
