import React, { useEffect, useState } from "react";
import { useCommonStats, useAccountStats, useTransactionStats } from "./helper/useStats";
import { useWeb3React } from "@web3-react/core";
import Button from "react-bootstrap-button-loader";
import poolAbi from "../../../json/BondingPool.json";
import ERC20Abi from "../../../json/ERC20.json";
import { parseEther } from "ethers/lib/utils";
import { toast } from "react-toastify";
import { getWeb3 } from "../../../hooks/connectors";
import { getContract } from "../../../hooks/contractHelper";
import templateImg from '../../../images/template.jpg';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Chart from 'chart.js/auto';
import { Line } from "react-chartjs-2";
import Holders from "./Holders";
import Transaction from "./Transaction";
import { saveBondTxdata } from "./helper/backend";
import { useAccount, useChainId } from "wagmi";
import { config } from "../../../config";
import { writeContract, waitForTransactionReceipt } from "@wagmi/core";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from 'lucide-react';

import discordIcon from "../../../images/discord.svg";
import telegramIcon from "../../../images/telegram.svg";
import websiteIcon from "../../../images/website.svg";
import twitterIcon from "../../../images/x.svg";

// Helper function for date formatting
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${day} ${month}. ${hours}:${minutes}`;
};

export default function ProjectBondingDetails() {
  const navigate = useNavigate();
  const chainId = useChainId()
  const { address: account } = useAccount()
  const [updater, setUpdater] = useState(1);
  const stats = useCommonStats(updater);
  const accStats = useAccountStats(updater);
  const txStats = useTransactionStats(updater, stats.poolAddress);
  const [createloading, setCreateloading] = useState(false);
  const [social, setSocial] = useState({});
  const [allowance, setAllowance] = useState(accStats.allowance);
  const [loading, setLoading] = useState(false);
  const [showHolder, setShowHolder] = useState(true);
  const [description, setDescription] = useState("");
  const [truncatedDescription, setTruncatedDescription] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState({
    logourl: "",
    bannerurl: "",
    website: "",
    facebook: "",
    twitter: "",
    github: "",
    telegram: "",
    instagram: "",
    discord: "",
    reddit: "",
    youtube: "",
    brief: "",
    blockstart: "",
  });
  const [imgageURL, setImageURL] = React.useState("");
  const [swapStats, setSwapStats] = useState({
    amount: 0,
    type: 1,
    balance: 0,
    currency: "ETH"
  });
  useEffect(() => {
    function getDetails() {
      let details = stats.poolDetails.toString().split("$#$");
      const socialObject = {
        logourl: details[0],
        bannerurl: details[1],
        website: details[2],
        blockstar: details[3],
        facebook: details[4],
        twitter: details[5],
        github: details[6],
        telegram: details[7],
        instagram: details[8],
        discord: details[9],
        reddit: details[10],
        youtube: details[11],
        brief: details[12],
      };
      setSocial(socialObject);
      setImageURL(socialObject.logourl);
      
      // Handle description
      if (socialObject.brief) {
        setDescription(socialObject.brief);
        const words = socialObject.brief.split(' ').slice(0, 6).join(' ') + '...';
        setTruncatedDescription(words);
      }
    }
    if (stats) {
      getDetails();
    }
  }, [stats, account, accStats, chainId]);

  const setimage = () => {
    setImageURL(templateImg);
  }

  const handleBack = () => {
    navigate('/', { 
      state: { 
        selectType: 'bonding',
        setBonding: true,
        setDegenMode: false
      } 
    });
  };

  // Chart Configuration
  const labels = txStats.length > 0 ? txStats.map((tx) => formatDate(tx.date)) : [];
  const chartData = txStats.length > 0 ? txStats.map((tx) => (
    tx.tokenPrice ? Number(tx.tokenPrice).toFixed(8) : 0
  )) : [];

  const dataZwei = {
    labels: labels,
    datasets: [
      {
        label: stats.tokenSymbol,
        fill: true,
        lineTension: 0.4,
        backgroundColor: "rgba(102, 4, 253, 0.1)",
        borderColor: "#6604FD",
        borderCapStyle: "round",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "round",
        pointBorderColor: "#6604FD",
        pointBackgroundColor: "#01F4A4",
        pointBorderWidth: 2,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: "#01F4A4",
        pointHoverBorderColor: "#6604FD",
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 10,
        data: chartData
      }
    ]
  };

  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
          borderDash: [5, 5]
        },
        ticks: {
          color: '#98A7B5',
          callback: function(value) {
            return '$' + Number(value).toFixed(8);
          }
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: '#98A7B5',
          maxRotation: 45,
          minRotation: 45
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          color: '#FFFFFF'
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: $${Number(context.raw).toFixed(8)}`;
          }
        }
      }
    },
    elements: {
      line: {
        tension: 0.4
      }
    }
  };

  // Handlers
  const handleBuy = (e) => {
    if (!account) {
      return;
    } else {
      e.preventDefault();
      setSwapStats({ ...swapStats, type: 1, balance: (Number(accStats.balance) / (10 ** 18)) })
      if (chainId == 56 || chainId == 97) {
        setSwapStats({ ...swapStats, type: 1, balance: (Number(accStats.balance) / (10 ** 18)), currency: 'BNB' })
      } else {
        setSwapStats({ ...swapStats, type: 1, balance: (Number(accStats.balance) / (10 ** 18)), currency: 'ETH' })
      }
    }
  }

  const handleSell = (e) => {
    if (!account) {
      return;
    } else {
      e.preventDefault();
      setSwapStats({ ...swapStats, type: 2, balance: (Number(accStats.tokenBalance) / (10 ** Number(stats.tokenDecimal))), currency: stats.tokenSymbol });
    }
  }

  const handleChangeAmount = (e) => {
    setSwapStats({ ...swapStats, amount: e.target.value });
    return;
  };

  const showHolders = () => {
    setShowHolder(true);
  }

  const showTransaction = () => {
    setShowHolder(false);
  }

  const handleApprove = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (account) {
        if (chainId) {
          let amount = parseEther("1000000000000000000000000000").toString();
          const result = await writeContract(config, {
            abi: ERC20Abi,
            address: stats.token,
            functionName: 'approve',
            args: [
              stats.poolAddress,
              amount
            ],
          });
  
          const resolveAfter3Sec = new Promise((resolve) =>
            setTimeout(resolve, 5000)
          );
          toast.promise(resolveAfter3Sec, {
            pending: "Waiting for confirmation 👌",
          });
  
          var interval = setInterval(async function () {
            const response = await waitForTransactionReceipt(config, { hash: result });
            if (response != null) {
              clearInterval(interval);
              if (response) {
                accStats.allowance = "1000000000000000000000000000";
                setAllowance(accStats.allowance);
                
                // Update stats immediately
                setUpdater(new Date().getTime());
                
                toast.success("Success! You little Degen 🦍");
                setLoading(false);
              } else {
                toast.error("Error! Your transaction failed.");
                setLoading(false);
              }
            }
          }, 5000);
        } else {
          toast.error("Please select Smart Chain Network!");
          setLoading(false);
        }
      } else {
        toast.error("Please Connect Wallet!");
        setLoading(false);
      }
    } catch (err) {
      console.error("Approval error:", err);
      toast.error(err.reason || "Transaction failed. Please try again.");
      setLoading(false);
    }
  };

  const handleSwap = async (e) => {
    e.preventDefault();
    setCreateloading(true);
    if (!account) {
      toast.error("Please connect wallet!");
      setCreateloading(false);
      return;
    }
  
    if (Number(swapStats.amount) <= Number(swapStats.balance)) {
      try {
        let web3 = getWeb3(chainId);
        let amount = parseEther(swapStats.amount);
        let result;
        if (swapStats.type == 1) {
          result = await writeContract(config, {
            abi: poolAbi,
            address: stats.poolAddress,
            functionName: 'swap',
            args: [
              amount, swapStats.type
            ],
            value: amount
          });
        } else {
          result = await writeContract(config, {
            abi: poolAbi,
            address: stats.poolAddress,
            functionName: 'swap',
            args: [
              amount, swapStats.type
            ],
          });
        }
  
        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 5000)
        );
        toast.promise(resolveAfter3Sec, {
          pending: "Waiting for confirmation 👌",
        });
  
        var interval = setInterval(async function () {
          const response = await waitForTransactionReceipt(config, { hash: result });
          if (response != null) {
            clearInterval(interval);
            if (response) {
              let poolContract = new web3.eth.Contract(poolAbi, stats.poolAddress);
              let tokenPrice = await poolContract.methods.getTokenPrice().call();
              tokenPrice = Number(tokenPrice) / 10 ** 18;
  
              const symbol = swapStats.type == 1 ? stats.currencySymbol : stats.tokenSymbol;
              const type = swapStats.type == 1 ? 'Buy' : 'Sell';
              const date = new Date();
  
              await saveBondTxdata({ 
                account, 
                chainId: chainId.toString(), 
                type, 
                amount: swapStats.amount, 
                poolAddress: stats.poolAddress, 
                tokenPrice, 
                symbol, 
                date 
              });
  
              setSwapStats({
                ...swapStats,
                amount: 0
              });
  
              setUpdater(new Date().getTime());
  
              setTimeout(() => {
                setUpdater(new Date().getTime() + 1);
              }, 3000);
  
              toast.success("Success! You little Degen 🦍");
              setCreateloading(false);
            } else {
              toast.error("Error! Your transaction failed.");
              setCreateloading(false);
            }
          }
        }, 5000);
      } catch (e) {
        console.error("Swap error:", e);
        toast.error("Can't sell all amount of token as Eth amount can't be zero on bonding pool!");
        setCreateloading(false);
      }
    } else {
      toast.error("Not enough token balance!")
      setCreateloading(false);
    }
  };

  // Start of the return statement
  return (

    <React.Fragment>
      <div className='detail-page container mt-3'>
        <button
          onClick={handleBack}
          className="flex items-center gap-2 text-white transition-all duration-200 rounded-lg"
          style={{
            background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
            border: 'none',
            marginLeft: '22px',
            marginTop: '10px',
            marginBottom: '10px',
            height: '28px',
            fontSize: '12px',
            fontWeight: '500',
            fontFamily: 'Quicksand, sans-serif',
            padding: '0 12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 2px 6px rgba(52, 97, 255, 0.2)',
            cursor: 'pointer',
            minWidth: '40px'
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.opacity = '0.9';
            e.currentTarget.style.transform = 'translateY(-1px)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.opacity = '1';
            e.currentTarget.style.transform = 'translateY(0)';
          }}
        >
          <ArrowLeft size={18} />
          <span></span>
        </button>

        {/* Stats Card */}
        <Card style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', marginBottom: '10px' }}>
          <Card.Body style={{ padding: '0' }}>
            <div style={{ padding: "2px", borderRadius: "8px", background: "linear-gradient(90deg, #6604FD 0%, #01F4A4 100%)" }}>
              <div className="row py-3 px-4 rounded-xl" style={{ backgroundColor: "var(--bcgame-sk-woodsmoke, #111415)", borderRadius: "8px", margin: "0" }}>
                
                {/* Token Info Section */}
                <div className="col-12 col-lg-5 mb-3 mb-lg-0 position-relative"> 
                  <div className="d-flex flex-column" style={{ gap: '0.5rem', padding: '12px 0' }}>
                    {/* Logo and Title Row */}
                    <div className="d-flex align-items-center" style={{ gap: '1rem' }}>
                      {/* Token Logo */}
                      <div style={{
                        flexShrink: 0,
                        width: '60px',
                        height: '60px',
                        padding: '3px',
                        borderRadius: '50%',
                        background: 'linear-gradient(180deg, #6604FD 0%, #01F4A4 100%)'
                      }}>
                        <div style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          overflow: 'hidden',
                          backgroundColor: 'var(--bcgame-sk-woodsmoke, #111415)'
                        }}>
                          <img
                            src={imgageURL}
                            onError={() => setImageURL(templateImg)}
                            alt="Token icon"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover'
                            }}
                          />
                        </div>
                      </div>

                      {/* Token Info */}
                      <div className="flex-grow-1" style={{ minWidth: 0 }}>
                        {/* Token Name and Symbol */}
                        <div className="text-white fw-bold" style={{
                          fontSize: 'clamp(1rem, 2.5vw, 1.25rem)',
                          lineHeight: '1.2',
                          marginBottom: '8px',
                          wordBreak: 'break-word'
                        }}>
                          <div className="d-flex flex-wrap align-items-center" style={{ gap: '4px' }}>
                            <span style={{ 
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}>
                              {stats.tokenName}
                            </span>
                            <span style={{ 
                              color: '#98A7B5',
                              fontSize: '0.9em'
                            }}>
                              ({stats.tokenSymbol})
                            </span>
                          </div>
                        </div>

                        {/* Social Links */}
                        <div className="d-flex gap-3">
                          {social.website && (
                            <a href={social.website} target="_blank" rel="noreferrer">
                              <img src={websiteIcon} alt="Website" style={{ width: '24px', height: '24px' }} />
                            </a>
                          )}
                          {social.discord && (
                            <a href={social.discord} target="_blank" rel="noreferrer">
                              <img src={discordIcon} alt="Discord" style={{ width: '24px', height: '24px' }} />
                            </a>
                          )}
                          {social.telegram && (
                            <a href={social.telegram} target="_blank" rel="noreferrer">
                              <img src={telegramIcon} alt="Telegram" style={{ width: '24px', height: '24px' }} />
                            </a>
                          )}
                          {social.twitter && (
                            <a href={social.twitter} target="_blank" rel="noreferrer">
                              <img src={twitterIcon} alt="Twitter" style={{ width: '24px', height: '24px' }} />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Description Row */}
                    <div className="mt-2" style={{ paddingLeft: '76px' }}>
                      <div className="d-flex align-items-center gap-2">
                        <p style={{ 
                          color: '#98A7B5', 
                          margin: 0,
                          fontSize: '0.85rem',
                          fontWeight: 'normal',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '80%'
                        }}>
                          {truncatedDescription}
                        </p>
                        <button
                          onClick={() => setShowModal(true)}
                          style={{
                            background: 'none',
                            border: 'none',
                            padding: 0,
                            color: '#6604FD',
                            fontSize: '0.75rem',
                            fontWeight: '500',
                            cursor: 'pointer',
                            whiteSpace: 'nowrap'
                          }}
                        >
                           see more
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Desktop divider */}
                  <div className="d-none d-lg-block position-absolute" style={{ 
                    width: '1px', 
                    height: '40px', 
                    backgroundColor: 'rgba(255,255,255,0.1)',
                    right: '0',
                    top: '50%',
                    transform: 'translateY(-50%)'
                  }}></div>
                </div>

                {/* Progress Section */}
                <div className="col-12 col-lg-3 mb-3 mb-lg-0 position-relative">
                  <div className="h-100 d-flex align-items-center">
                    <div className="w-100">
                      <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: '8px' }}>
                        <span className="text-gray-300" style={{ fontSize: '1rem' }}>Progress</span>
                        <span style={{ fontSize: '1rem', color: '#22c55e' }}>
                          {parseFloat(stats.currentMK / 69000).toFixed(2)}%
                        </span>
                      </div>
                      <div style={{
                        height: '14px',
                        backgroundColor: 'rgb(98, 28, 188)',
                        borderRadius: '6px',
                        overflow: 'hidden'
                      }}>
                        <div
                          style={{
                            width: `${(parseFloat(stats.currentMK) / 690).toFixed(2)}%`,
                            backgroundColor: '#22c55e',
                            height: '100%',
                            borderRadius: '6px',
                            transition: 'width 0.3s ease'
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Desktop divider */}
                  <div className="d-none d-lg-block position-absolute" style={{ 
                    width: '1px', 
                    height: '40px', 
                    backgroundColor: 'rgba(255,255,255,0.1)',
                    right: '0',
                    top: '50%',
                    transform: 'translateY(-50%)'
                  }}></div>
                </div>

                {/* Market Cap and Holders Container */}
                <div className="col-12 col-lg-4 mt-2 mt-lg-0">
                  <div className="d-flex align-items-center h-100">
                    <div className="flex-grow-1 text-center">
                      <div className="text-gray-300" style={{ fontSize: '1rem' }}>Market Cap</div>
                      <div className="fw-semibold" style={{ fontSize: '0.9rem', color: '#22c55e' }}>
                        ${parseFloat(stats.currentMK).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </div>
                    </div>

                    <div style={{ 
                      width: '1px', 
                      height: '40px', 
                      backgroundColor: 'rgba(255,255,255,0.1)',
                      margin: '0 20px'
                    }}></div>

                    <div className="flex-grow-1 text-center">
                      <div className="text-gray-300" style={{ fontSize: '1rem' }}>Holders</div>
                      <div className="fw-semibold" style={{ fontSize: '0.9rem', color: '#22c55e' }}>
                        {stats.holders.length}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>

        {/* Description Modal */}
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          centered
        >
          <Modal.Header 
            closeButton
            style={{
              backgroundColor: "var(--bcgame-sk-woodsmoke, #111415)",
              borderBottom: '1px solid rgba(255,255,255,0.1)',
              padding: '1rem'
            }}
          >
            <Modal.Title style={{ color: '#FFFFFF' }}>Project Description</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: "var(--bcgame-sk-woodsmoke, #111415)",
              color: '#98A7B5',
              padding: '1rem',
              maxHeight: '60vh',
              overflowY: 'auto'
            }}
          >
            <p style={{
              whiteSpace: 'pre-wrap',
              fontSize: '0.9rem',
              lineHeight: '1.6',
              margin: 0
            }}>
              {description}
            </p>
          </Modal.Body>
        </Modal>

        {/* Chart Card */}
        <Card style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', marginBottom: '0px', marginTop: '0px' }}>
          <Card.Body style={{ padding: '0' }}>
            <div>
              <div style={{ backgroundColor: "var(--bcgame-sk-woodsmoke, #111415)", borderRadius: "8px", padding: '20px' }}>
                <Line data={dataZwei} options={chartOptions} style={{ width: '100%', height: '400px' }} />
              </div>
            </div>
          </Card.Body>
        </Card>

        {/* Buy/Sell Section*/}
{/* Buy/Sell Section*/}
<div className="row g-3 mt-1">
  <div className="col-12 col-lg-6">
    <Card style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', marginBottom: '0px', marginTop: '0px' }}>
      <Card.Body style={{ padding: '0' }}>
        <div style={{ 
          padding: "2px", 
          borderRadius: "12px", 
          background: "linear-gradient(90deg, rgba(54, 97, 255, 0.5) 0%, rgba(132, 84, 235, 0.5) 100%)",
        }}>
          <div style={{ 
            backgroundColor: "var(--bcgame-sk-woodsmoke, #111415)", 
            borderRadius: "12px", 
            padding: '20px' 
          }}>
            {/* Buy/Sell Buttons Row */}
            <div className="row mb-4 gx-3">
              <div className="col-6">
                <button
                  style={{
                    width: "100%",
                    background: swapStats.type === 1 
                      ? "linear-gradient(90deg, #3461FF 0%, #8454EB 100%)"
                      : "rgba(255, 255, 255, 0.1)",
                    borderRadius: "8px",
                    color: "#FFFFFF",
                    border: "none",
                    padding: "12px",
                    fontFamily: "Quicksand, Helvetica",
                    fontSize: "16px",
                    fontWeight: "500",
                    cursor: "pointer",
                    transition: "all 0.3s ease"
                  }}
                  type="button"
                  className="btn-gradient"
                  onClick={(e) => handleBuy(e)}
                >
                  BUY
                </button>
              </div>
              <div className="col-6">
                <button
                  style={{
                    width: "100%",
                    background: swapStats.type === 2 
                      ? "linear-gradient(90deg, #E02B89 0%, #8454EB 100%)"
                      : "rgba(255, 255, 255, 0.1)",
                    borderRadius: "8px",
                    color: "#FFFFFF",
                    border: "none",
                    padding: "12px",
                    fontFamily: "Quicksand, Helvetica",
                    fontSize: "16px",
                    fontWeight: "500",
                    cursor: "pointer",
                    transition: "all 0.3s ease"
                  }}
                  type="button"
                  className="btn-gradient"
                  onClick={(e) => handleSell(e)}
                >
                  SELL
                </button>
              </div>
            </div>

            {/* Max Slippage Label */}
            <div style={{ marginBottom: '10px' }}>
              <span style={{ 
                color: 'rgba(255, 255, 255, 0.5)', 
                fontSize: '14px', 
                fontWeight: '400' 
              }}>
                MAX SLIPPAGE
              </span>
            </div>

            {/* Input Section */}
            <div className="mb-4">
              <div style={{
                backgroundColor: '#1A1B1E',
                borderRadius: "8px",
                border: '1px solid rgba(255, 255, 255, 0.1)',
                display: 'flex',
                alignItems: 'center',
                padding: '8px 12px'
              }}>
                <input
                  style={{
                    width: '100%',
                    background: 'transparent',
                    border: 'none',
                    color: '#FFFFFF',
                    fontSize: '16px',
                    outline: 'none'
                  }}
                  className="amount-input"
                  onChange={(e) => handleChangeAmount(e)}
                  value={swapStats.amount}
                  type="number"
                  placeholder="0.0"
                />
                <div style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: '8px',
                  paddingLeft: '12px',
                  borderLeft: '1px solid rgba(255, 255, 255, 0.1)'
                }}>
                  {swapStats.type === 1 ? 'ETH' : stats.tokenSymbol}
                </div>
              </div>
              <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                marginTop: '8px'
              }}>
                <div style={{ display: 'flex', gap: '4px'}}>
                  {['25%', '50%', '75%', 'MAX'].map((percent) => (
                    <button
                      key={percent}
                      style={{
                        background: 'rgba(255, 255, 255, 0.1)',
                        border: 'none',
                        borderRadius: '4px',
                        color: 'rgba(255, 255, 255, 0.3)',
                        padding: '4px 8px',
                        fontSize: '12px',
                        cursor: 'not-allowed',
                        textDecoration: 'line-through',
                        position: 'relative',
                        opacity: 0.5
                      }}
                      disabled={true}
                    >
                      {percent}
                    </button>
                  ))}
                  <button
                    style={{
                      background: 'rgba(54, 97, 255, 0.2)',
                      border: 'none',
                      borderRadius: '4px',
                      color: '#3461FF',
                      padding: '4px 8px',
                      fontSize: '12px',
                      cursor: 'not-allowed',
                      textDecoration: 'line-through',
                      opacity: 0.5
                    }}
                    disabled={true}
                  >
                    RESET
                  </button>
                </div>
              </div>
              <div style={{ 
                color: 'rgba(255, 255, 255, 0.5)', 
                fontSize: '14px',
                marginTop: '8px',
                textAlign: 'right'
              }}>
                Balance: {swapStats.balance.toFixed(5)} {swapStats.currency}
              </div>
              <small className="text-danger mt-1 d-block">{error.decimals}</small>
            </div>

            {/* Place Order/Approve Button */}
            {swapStats.type == 1 ? (
              <button
                style={{
                  width: "100%",
                  background: "linear-gradient(90deg, #3461FF 0%, #8454EB 100%)",
                  borderRadius: "8px",
                  color: "#FFFFFF",
                  border: "none",
                  padding: "14px",
                  fontFamily: "Quicksand, Helvetica",
                  fontSize: "16px",
                  fontWeight: "500",
                  cursor: "pointer",
                  transition: "opacity 0.3s"
                }}
                type="button"
                className="btn-gradient"
                disabled={createloading}
                onClick={(e) => handleSwap(e)}
              >
                {createloading ? 'Processing...' : 'PLACE ORDER'}
              </button>
            ) : (
              Number(allowance) >= Number(swapStats.amount) || Number(accStats.allowance) >= Number(swapStats.amount) ? (
                <button
                  style={{
                    width: "100%",
                    background: "linear-gradient(90deg, #3461FF 0%, #8454EB 100%)",
                    borderRadius: "8px",
                    color: "#FFFFFF",
                    border: "none",
                    padding: "14px",
                    fontFamily: "Quicksand, Helvetica",
                    fontSize: "16px",
                    fontWeight: "500",
                    cursor: "pointer",
                    transition: "opacity 0.3s"
                  }}
                  type="button"
                  className="btn-gradient"
                  disabled={createloading}
                  onClick={(e) => handleSwap(e)}
                >
                  {createloading ? 'Processing...' : 'PLACE ORDER'}
                </button>
              ) : (
                <button
                  style={{
                    width: "100%",
                    background: "linear-gradient(90deg, #E02B89 0%, #8454EB 100%)",
                    borderRadius: "8px",
                    color: "#FFFFFF",
                    border: "none",
                    padding: "14px",
                    fontFamily: "Quicksand, Helvetica",
                    fontSize: "16px",
                    fontWeight: "500",
                    cursor: "pointer",
                    transition: "opacity 0.3s"
                  }}
                  type="button"
                  className="btn-gradient"
                  disabled={loading}
                  onClick={(e) => handleApprove(e)}
                >
                  {loading ? 'Processing...' : 'APPROVE'}
                </button>
              )
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  </div>

  <div className="col-12 col-lg-6">
    {/* Holders/Transactions Section */}
    <Card style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', marginBottom: '20px' }}>
      <Card.Body style={{ padding: '0' }}>
        <div 
          style={{
            padding: "2px",
            borderRadius: "12px",
            background: "linear-gradient(90deg, rgba(54, 97, 255, 0.5) 0%, rgba(132, 84, 235, 0.5) 100%)",
          }}
        >
          <div 
            style={{
              backgroundColor: "var(--bcgame-sk-woodsmoke, #111415)",
              borderRadius: "12px",
              padding: '20px'
            }}
          >
            <Nav variant="pills" defaultActiveKey="#first" style={{ gap: '10px' }}>
              <Nav.Item>
                <Nav.Link
                  href="#first"
                  onClick={() => showHolders()}
                  style={{
                    background: showHolder ? 'linear-gradient(180deg, #6600FF 0%, #20A595 100%)' : 'rgba(255, 255, 255, 0.1)',
                    border: 'none',
                    color: showHolder ? '#FFFFFF' : '#98A7B5',
                    borderRadius: '8px',
                    padding: '8px 16px',
                  }}
                >
                  Holders
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="#link"
                  onClick={() => showTransaction()}
                  style={{
                    background: !showHolder ? 'linear-gradient(180deg, #6600FF 0%, #20A595 100%)' : 'rgba(255, 255, 255, 0.1)',
                    border: 'none',
                    color: !showHolder ? '#FFFFFF' : '#98A7B5',
                    borderRadius: '8px',
                    padding: '8px 16px',
                  }}
                >
                  Transaction
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <div 
              style={{
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '0px'
              }}
            >
              {showHolder ? (
                <div style={{ minWidth: '400px' }}>
                  <Holders holders={stats.holders} token={stats.token} governance={stats.governance} />
                </div>
              ) : (
                <div style={{ minWidth: '400px' }}>
                  <Transaction txStats={txStats} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  </div>
</div>

<style>
        {`
          @media (max-width: 768px) {
            .px-4 {
              padding-left: 1rem !important;
              padding-right: 1rem !important;
            }
            .py-3 {
              padding-top: 0.75rem !important;
              padding-bottom: 0.75rem !important;
            }
            .text-center {
              text-align: center !important;
            }
          }
        `}
      </style>
    </div>
  </React.Fragment>
);
}