// Part 1: Imports and Networks.


import React, { useState, useEffect, useRef, useMemo} from 'react';
import { useAccount, useChainId } from "wagmi";
import { MulticallContractWeb3 } from "../hooks/useContracts";
import { getWeb3 } from "../hooks/connectors";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import axios from 'axios';
import { toast } from "react-toastify";
import './WalletAnalytics.css';
import PriceService from '../services/PriceService';


//images
import lineaIcon from '../images/linea.png';
import scrollIcon from '../images/scroll.png';



const TokenImage = ({ logo, alt, fallback }) => {
  const [src, setSrc] = useState(logo);
  const [error, setError] = useState(false);

  const handleError = () => {
    if (!error) {
      setError(true);
      setSrc(fallback || '/default-token.png');
    }
  };

  return (
    <img 
      src={src} 
      alt={alt} 
      className="token-icon" 
      onError={handleError}
      style={{ width: '24px', height: '24px', objectFit: 'contain' }}
    />
  );
};


const SUPPORTED_NETWORKS = {
  '1': {
    name: 'Ethereum',
    nativeCurrency: 'ETH',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png',
    explorer: 'https://etherscan.io',
    dexScreener: 'https://dexscreener.com',
    subgraph: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
    defaultDex: 'Uniswap V3',
    moralisChain: 'eth'
  },
  '56': {
    name: 'BNB Chain',
    nativeCurrency: 'BNB',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/binance/info/logo.png',
    explorer: 'https://bscscan.com',
    dexScreener: 'https://dexscreener.com/bsc',
    subgraph: 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-bsc',
    defaultDex: 'PancakeSwap',
    moralisChain: 'bsc'
  },
  '137': {
    name: 'Polygon',
    nativeCurrency: 'MATIC',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/info/logo.png',
    explorer: 'https://polygonscan.com',
    dexScreener: 'https://dexscreener.com/polygon',
    subgraph: 'https://api.thegraph.com/subgraphs/name/sameepsi/quickswap-v3',
    defaultDex: 'QuickSwap',
    moralisChain: 'polygon'
  },
  '42161': {
    name: 'Arbitrum',
    nativeCurrency: 'ETH',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/arbitrum/info/logo.png',
    explorer: 'https://arbiscan.io',
    dexScreener: 'https://dexscreener.com/arbitrum',
    subgraph: 'https://api.thegraph.com/subgraphs/name/camelot-exchange/exchange',
    defaultDex: 'Camelot',
    moralisChain: 'arbitrum'
  },
  '10': {
    name: 'Optimism',
    nativeCurrency: 'ETH',
    icon: 'https://raw.githubusercontent.com/ethereum-optimism/brand-kit/main/assets/svg/Profile-Logo.svg',
    explorer: 'https://optimistic.etherscan.io',
    dexScreener: 'https://dexscreener.com/optimism',
    subgraph: 'https://api.thegraph.com/subgraphs/name/velodrome-finance/velodrome-v2',
    defaultDex: 'Velodrome',
    moralisChain: 'optimism'
  },
  '8453': {
    name: 'Base',
    nativeCurrency: 'ETH',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/base/info/logo.png',
    explorer: 'https://basescan.org',
    dexScreener: 'https://dexscreener.com/base',
    subgraph: 'https://api.studio.thegraph.com/query/48211/baseswap/version/latest',
    defaultDex: 'BaseSwap',
    moralisChain: 'base'
  },
  '59144': {
    name: 'Linea',
    nativeCurrency: 'ETH',
    icon: lineaIcon,  // Use imported image here
    explorer: 'https://lineascan.build',
    dexScreener: 'https://dexscreener.com/linea',
    subgraph: 'https://api.thegraph.com/subgraphs/name/horizondex/linea-exchange',
    defaultDex: 'HorizonDEX',
    moralisChain: 'linea'
  },
  '43114': {
    name: 'Avalanche',
    nativeCurrency: 'AVAX',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/avalanchec/info/logo.png',
    explorer: 'https://snowtrace.io',
    dexScreener: 'https://dexscreener.com/avalanche',
    subgraph: 'https://api.thegraph.com/subgraphs/name/traderjoe-xyz/exchange',
    defaultDex: 'Trader Joe',
    moralisChain: 'avalanche'
  },
  '250': {
    name: 'Fantom',
    nativeCurrency: 'FTM',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/fantom/info/logo.png',
    explorer: 'https://ftmscan.com',
    dexScreener: 'https://dexscreener.com/fantom',
    subgraph: 'https://api.thegraph.com/subgraphs/name/spookyswap/exchange',
    defaultDex: 'SpookySwap',
    moralisChain: 'fantom'
  
},
  '324': {
    name: 'zkSync Era',
    nativeCurrency: 'ETH',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/zksync/info/logo.png',
    explorer: 'https://explorer.zksync.io',
    dexScreener: 'https://dexscreener.com/zksync',
    subgraph: 'https://api.studio.thegraph.com/query/45376/syncswap/v0.1',
    defaultDex: 'SyncSwap',
    moralisChain: 'zksync'
  },
  '1101': {
    name: 'Polygon zkEVM',
    nativeCurrency: 'ETH',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygonzkevm/info/logo.png',
    explorer: 'https://zkevm.polygonscan.com',
    dexScreener: 'https://dexscreener.com/polygonzkevm',
    subgraph: 'https://api.studio.thegraph.com/query/45376/quickswap-v3-zkevm/v0.0.1',
    defaultDex: 'QuickSwap',
    moralisChain: 'polygon_zkevm'
  },
  '169': {
    name: 'Manta Pacific',
    nativeCurrency: 'ETH',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/manta/info/logo.png',
    explorer: 'https://pacific-explorer.manta.network',
    dexScreener: 'https://dexscreener.com/manta',
    subgraph: 'https://api.studio.thegraph.com/query/45376/mantapacific/v0.0.1',
    defaultDex: 'QuickSwap',
    moralisChain: 'manta'
  },
  '1284': {
    name: 'Moonbeam',
    nativeCurrency: 'GLMR',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/moonbeam/info/logo.png',
    explorer: 'https://moonbeam.moonscan.io',
    dexScreener: 'https://dexscreener.com/moonbeam',
    subgraph: 'https://api.thegraph.com/subgraphs/name/stellaswap/stella-swap',
    defaultDex: 'StellaSwap',
    moralisChain: 'moonbeam'
  },
  '1285': {
    name: 'Moonriver',
    nativeCurrency: 'MOVR',
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/moonriver/info/logo.png',
    explorer: 'https://moonriver.moonscan.io',
    dexScreener: 'https://dexscreener.com/moonriver',
    subgraph: 'https://api.thegraph.com/subgraphs/name/solarbeamio/solarbeam',
    defaultDex: 'Solarbeam',
    moralisChain: 'moonriver'
  },
  '100': {
    name: 'Gnosis',
    nativeCurrency: 'xDAI',
    icon: 'https://assets.coingecko.com/coins/images/11062/large/Identity-Primary-DarkBG.png',  // Updated Gnosis logo
    explorer: 'https://gnosisscan.io',
    dexScreener: 'https://dexscreener.com/gnosis',
    subgraph: 'https://api.thegraph.com/subgraphs/name/sushiswap/xdai-exchange',
    defaultDex: 'SushiSwap',
    moralisChain: 'gnosis'
  },
'534352': {
    name: 'Scroll',
    nativeCurrency: 'ETH',
    icon: scrollIcon,
    explorer: 'https://scrollscan.com',
    dexScreener: 'https://dexscreener.com/scroll',
    subgraph: 'https://api.studio.thegraph.com/query/48457/scrollex/v0.0.1',
    defaultDex: 'ScrollEx',
    moralisChain: 'scroll'
},
'7777777': {
    name: 'Zora',
    nativeCurrency: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/30567/large/zora.jpg',
    explorer: 'https://explorer.zora.energy',
    dexScreener: 'https://dexscreener.com/zora',
    subgraph: 'https://api.studio.thegraph.com/query/48211/zoraswap/v0.0.1',
    defaultDex: 'ZoraSwap',
    moralisChain: 'zora'
},
'42170': {
    name: 'Arbitrum Nova',
    nativeCurrency: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/28284/large/arb-nova.png',
    explorer: 'https://nova.arbiscan.io',
    dexScreener: 'https://dexscreener.com/arbitrum-nova',
    subgraph: 'https://api.thegraph.com/subgraphs/name/sushiswap/arbitrum-nova',
    defaultDex: 'SushiSwap',
    moralisChain: 'arbitrum_nova'
},
'42220': {
    name: 'Celo',
    nativeCurrency: 'CELO',
    icon: 'https://assets.coingecko.com/coins/images/11090/large/InjXBNx9_400x400.jpg',
    explorer: 'https://celoscan.io',
    dexScreener: 'https://dexscreener.com/celo',
    subgraph: 'https://api.thegraph.com/subgraphs/name/ubeswap/ubeswap',
    defaultDex: 'Ubeswap',
    moralisChain: 'celo'
},
'1666600000': {
    name: 'Harmony',
    nativeCurrency: 'ONE',
    icon: 'https://assets.coingecko.com/coins/images/4344/large/Y88JAze.png',
    explorer: 'https://explorer.harmony.one',
    dexScreener: 'https://dexscreener.com/harmony',
    subgraph: 'https://api.thegraph.com/subgraphs/name/sushiswap/harmony-exchange',
    defaultDex: 'SushiSwap',
    moralisChain: 'harmony'
},
'2222': {
    name: 'Kava',
    nativeCurrency: 'KAVA',
    icon: 'https://assets.coingecko.com/coins/images/9761/large/kava.jpg',
    explorer: 'https://explorer.kava.io',
    dexScreener: 'https://dexscreener.com/kava',
    subgraph: 'https://api.thegraph.com/subgraphs/name/sushiswap/kava-exchange',
    defaultDex: 'SushiSwap',
    moralisChain: 'kava'
},
'1088': {
    name: 'Metis',
    nativeCurrency: 'METIS',
    icon: 'https://assets.coingecko.com/coins/images/15595/large/metis.PNG',
    explorer: 'https://andromeda-explorer.metis.io',
    dexScreener: 'https://dexscreener.com/metis',
    subgraph: 'https://api.thegraph.com/subgraphs/name/netswap/netswap',
    defaultDex: 'Netswap',
    moralisChain: 'metis'
},
'5000': {
    name: 'Mantle',
    nativeCurrency: 'MNT',
    icon: 'https://assets.coingecko.com/coins/images/30980/large/token-logo.png',
    explorer: 'https://explorer.mantle.xyz',
    dexScreener: 'https://dexscreener.com/mantle',
    subgraph: 'https://api.studio.thegraph.com/query/45376/agni-exchange/v0.0.1',
    defaultDex: 'Agni',
    moralisChain: 'mantle'
},
'204': {
    name: 'opBNB',
    nativeCurrency: 'BNB',
    icon: 'https://assets.coingecko.com/coins/images/30746/large/opBNB.png',
    explorer: 'https://opbnb.bscscan.com',
    dexScreener: 'https://dexscreener.com/opbnb',
    subgraph: 'https://api.studio.thegraph.com/query/45376/pancakeswap-opbnb/v0.0.1',
    defaultDex: 'PancakeSwap',
    moralisChain: 'opbnb'
},
'7700': {
    name: 'Canto',
    nativeCurrency: 'CANTO',
    icon: 'https://assets.coingecko.com/coins/images/26926/large/canto.png',
    explorer: 'https://cantoscan.com',
    dexScreener: 'https://dexscreener.com/canto',
    subgraph: 'https://api.thegraph.com/subgraphs/name/cantoswap/dex',
    defaultDex: 'CantoSwap',
    moralisChain: 'canto'
},
'1501': {
    name: 'BeamSwap',
    nativeCurrency: 'BEAM',
    icon: 'https://assets.coingecko.com/coins/images/23704/large/beam.png',
    explorer: 'https://subnets.avax.network/beam',
    dexScreener: 'https://dexscreener.com/beam',
    subgraph: 'https://api.thegraph.com/subgraphs/name/beamswap/beam-exchange',
    defaultDex: 'BeamSwap',
    moralisChain: 'beam'
},
'42262': {
    name: 'Oasis Emerald',
    nativeCurrency: 'ROSE',
    icon: 'https://assets.coingecko.com/coins/images/13162/large/rose.png',
    explorer: 'https://explorer.emerald.oasis.dev',
    dexScreener: 'https://dexscreener.com/oasis',
    subgraph: 'https://api.thegraph.com/subgraphs/name/yumiswap/exchange',
    defaultDex: 'YumiSwap',
    moralisChain: 'oasis'
},
'1313161554': {
    name: 'Aurora',
    nativeCurrency: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/20582/large/aurora.jpeg',
    explorer: 'https://aurorascan.dev',
    dexScreener: 'https://dexscreener.com/aurora',
    subgraph: 'https://api.thegraph.com/subgraphs/name/trisolaris-labs/exchange',
    defaultDex: 'Trisolaris',
    moralisChain: 'aurora'
},
'34443': {
    name: 'Mode',
    nativeCurrency: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/33910/large/mode.png',
    explorer: 'https://explorer.mode.network',
    dexScreener: 'https://dexscreener.com/mode',
    subgraph: 'https://api.studio.thegraph.com/query/45376/modeswap/v0.0.1',
    defaultDex: 'ModeSwap',
    moralisChain: 'mode'
},
'369': {
    name: 'PulseChain',
    nativeCurrency: 'PLS',
    icon: 'https://assets.coingecko.com/coins/images/31122/large/pulse.png',
    explorer: 'https://scan.pulsechain.com',
    dexScreener: 'https://dexscreener.com/pulsechain',
    subgraph: 'https://api.thegraph.com/subgraphs/name/pulsechain/exchange',
    defaultDex: 'PulseX',
    moralisChain: 'pulsechain'
},
'122': {
    name: 'Fuse',
    nativeCurrency: 'FUSE',
    icon: 'https://assets.coingecko.com/coins/images/10347/large/vQxX3Pr.png',
    explorer: 'https://explorer.fuse.io',
    dexScreener: 'https://dexscreener.com/fuse',
    subgraph: 'https://api.thegraph.com/subgraphs/name/fuseio/fuseswap',
    defaultDex: 'FuseSwap',
    moralisChain: 'fuse'
},
'10000': {
    name: 'SmartBCH',
    nativeCurrency: 'BCH',
    icon: 'https://assets.coingecko.com/coins/images/15620/large/smartbch.png',
    explorer: 'https://www.smartscan.cash',
    dexScreener: 'https://dexscreener.com/smartbch',
    subgraph: 'https://api.thegraph.com/subgraphs/name/mistswap/exchange',
    defaultDex: 'MistSwap',
    moralisChain: 'smartbch'
},
'57': {
    name: 'Syscoin',
    nativeCurrency: 'SYS',
    icon: 'https://assets.coingecko.com/coins/images/119/large/Syscoin.png',
    explorer: 'https://explorer.syscoin.org',
    dexScreener: 'https://dexscreener.com/syscoin',
    subgraph: 'https://api.thegraph.com/subgraphs/name/pegasys/exchange',
    defaultDex: 'Pegasys',
    moralisChain: 'syscoin'
},
'25': {
    name: 'Cronos',
    nativeCurrency: 'CRO',
    icon: 'https://assets.coingecko.com/coins/images/7310/large/cro.png',
    explorer: 'https://cronoscan.com',
    dexScreener: 'https://dexscreener.com/cronos',
    subgraph: 'https://api.thegraph.com/subgraphs/name/croswap/exchange',
    defaultDex: 'CroSwap',
    moralisChain: 'cronos'
},
'288': {
    name: 'Boba',
    nativeCurrency: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/20285/large/BOBA.png',
    explorer: 'https://bobascan.com',
    dexScreener: 'https://dexscreener.com/boba',
    subgraph: 'https://api.thegraph.com/subgraphs/name/bobaswap/exchange',
    defaultDex: 'BobaSwap',
    moralisChain: 'boba'
},
'82': {
    name: 'Meter',
    nativeCurrency: 'MTR',
    icon: 'https://assets.coingecko.com/coins/images/11848/large/meter.png',
    explorer: 'https://scan.meter.io',
    dexScreener: 'https://dexscreener.com/meter',
    subgraph: 'https://api.thegraph.com/subgraphs/name/voltswap/exchange',
    defaultDex: 'VoltSwap',
    moralisChain: 'meter'
},
'1234': {
    name: 'Step Network',
    nativeCurrency: 'FITFI',
    icon: 'https://assets.coingecko.com/coins/images/25015/large/200x200.png',
    explorer: 'https://stepscan.io',
    dexScreener: 'https://dexscreener.com/step',
    subgraph: 'https://api.thegraph.com/subgraphs/name/stepex/exchange',
    defaultDex: 'StepEx',
    moralisChain: 'step'
},
'42766': {
    name: 'ZKFair',
    nativeCurrency: 'USDC',
    icon: 'https://assets.coingecko.com/coins/images/33600/large/zkfair.png',
    explorer: 'https://scan.zkfair.io',
    dexScreener: 'https://dexscreener.com/zkfair',
    subgraph: 'https://api.studio.thegraph.com/query/45376/zkfair/v0.0.1',
    defaultDex: 'ZKFairSwap',
    moralisChain: 'zkfair'
},
'88888': {
    name: 'Chiliz',
    nativeCurrency: 'CHZ',
    icon: 'https://assets.coingecko.com/coins/images/869/large/Chiliz.png',
    explorer: 'https://scan.chiliz.com',
    dexScreener: 'https://dexscreener.com/chiliz',
    subgraph: 'https://api.thegraph.com/subgraphs/name/chilizswap/exchange',
    defaultDex: 'ChilizSwap',
    moralisChain: 'chiliz'
},
'42161500': {
    name: 'Rollux',
    nativeCurrency: 'SYS',
    icon: 'https://assets.coingecko.com/coins/images/30284/large/Rollux.png',
    explorer: 'https://explorer.rollux.com',
    dexScreener: 'https://dexscreener.com/rollux',
    subgraph: 'https://api.thegraph.com/subgraphs/name/pegasys/rollux',
    defaultDex: 'Pegasys',
    moralisChain: 'rollux'
},
'245022934': {
    name: 'Neon EVM',
    nativeCurrency: 'NEON',
    icon: 'https://assets.coingecko.com/coins/images/25097/large/photo_2024-01-03_09-18-52.png',
    explorer: 'https://neonscan.org',
    dexScreener: 'https://dexscreener.com/neon',
    subgraph: 'https://api.thegraph.com/subgraphs/name/neonswap/exchange',
    defaultDex: 'NeonSwap',
    moralisChain: 'neon'
},
'8217': {
    name: 'Klaytn',
    nativeCurrency: 'KLAY',
    icon: 'https://assets.coingecko.com/coins/images/9672/large/klaytn.png',
    explorer: 'https://scope.klaytn.com',
    dexScreener: 'https://dexscreener.com/klay',
    subgraph: 'https://api.thegraph.com/subgraphs/name/klayswap/exchange',
    defaultDex: 'KlaySwap',
    moralisChain: 'klaytn'
},
'592': {
    name: 'Astar',
    nativeCurrency: 'ASTR',
    icon: 'https://assets.coingecko.com/coins/images/22617/large/astr.png',
    explorer: 'https://astar.subscan.io',
    dexScreener: 'https://dexscreener.com/astar',
    subgraph: 'https://api.thegraph.com/subgraphs/name/arthswap/exchange',
    defaultDex: 'ArthSwap',
    moralisChain: 'astar'
},
'106': {
    name: 'Velas',
    nativeCurrency: 'VLX',
    icon: 'https://assets.coingecko.com/coins/images/9651/large/velas.png',
    explorer: 'https://evmexplorer.velas.com',
    dexScreener: 'https://dexscreener.com/velas',
    subgraph: 'https://api.thegraph.com/subgraphs/name/velasswap/exchange',
    defaultDex: 'VelasSwap',
    moralisChain: 'velas'
},
'1116': {
    name: 'Core DAO',
    nativeCurrency: 'CORE',
    icon: 'https://assets.coingecko.com/coins/images/28938/large/core.png',
    explorer: 'https://scan.coredao.org',
    dexScreener: 'https://dexscreener.com/core',
    subgraph: 'https://api.thegraph.com/subgraphs/name/coreswap/exchange',
    defaultDex: 'CoreSwap',
    moralisChain: 'core'
},
'109': {
    name: 'Shibarium',
    nativeCurrency: 'BONE',
    icon: 'https://assets.coingecko.com/coins/images/30434/large/shibarium.jpg',
    explorer: 'https://shibariumscan.io',
    dexScreener: 'https://dexscreener.com/shibarium',
    subgraph: 'https://api.thegraph.com/subgraphs/name/shibaswap/exchange',
    defaultDex: 'ShibaSwap',
    moralisChain: 'shibarium'
},
'40': {
    name: 'Telos',
    nativeCurrency: 'TLOS',
    icon: 'https://assets.coingecko.com/coins/images/7598/large/Telos_Icon_FA-1-1.png',
    explorer: 'https://teloscan.io',
    dexScreener: 'https://dexscreener.com/telos',
    subgraph: 'https://api.thegraph.com/subgraphs/name/zappy/exchange',
    defaultDex: 'ZappySwap',
    moralisChain: 'telos'
},
'195': {
    name: 'X1 Network',
    nativeCurrency: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/32418/large/x1_network.png',
    explorer: 'https://www.okx.com/explorer/x1',
    dexScreener: 'https://dexscreener.com/x1',
    subgraph: 'https://api.studio.thegraph.com/query/45376/x1swap/v0.0.1',
    defaultDex: 'X1Swap',
    moralisChain: 'x1'
},
'7332': {
    name: 'Horizen EON',
    nativeCurrency: 'ZEN',
    icon: 'https://assets.coingecko.com/coins/images/691/large/horizen.png',
    explorer: 'https://eon.horizen.io',
    dexScreener: 'https://dexscreener.com/horizen',
    subgraph: 'https://api.studio.thegraph.com/query/45376/horizonswap/v0.0.1',
    defaultDex: 'HorizonSwap',
    moralisChain: 'horizen'
},
'14': {
    name: 'Flare',
    nativeCurrency: 'FLR',
    icon: 'https://assets.coingecko.com/coins/images/28624/large/FLR-icon.png',
    explorer: 'https://flare-explorer.flare.network',
    dexScreener: 'https://dexscreener.com/flare',
    subgraph: 'https://api.thegraph.com/subgraphs/name/flareswap/exchange',
    defaultDex: 'FlareSwap',
    moralisChain: 'flare'
},
'1001': {
    name: 'Conflux eSpace',
    nativeCurrency: 'CFX',
    icon: 'https://assets.coingecko.com/coins/images/13079/large/3vuYMbjN.png',
    explorer: 'https://evm.confluxscan.io',
    dexScreener: 'https://dexscreener.com/conflux',
    subgraph: 'https://api.thegraph.com/subgraphs/name/swappi/exchange',
    defaultDex: 'Swappi',
    moralisChain: 'conflux'
},
'73772': {
    name: 'Shimmer',
    nativeCurrency: 'SMR',
    icon: 'https://assets.coingecko.com/coins/images/28582/large/shimmer.png',
    explorer: 'https://explorer.shimmer.network',
    dexScreener: 'https://dexscreener.com/shimmer',
    subgraph: 'https://api.thegraph.com/subgraphs/name/shimmerswap/exchange',
    defaultDex: 'ShimmerSwap',
    moralisChain: 'shimmer'
},
'42220': {
    name: 'Oasis Sapphire',
    nativeCurrency: 'ROSE',
    icon: 'https://assets.coingecko.com/coins/images/13162/large/rose.png',
    explorer: 'https://explorer.sapphire.oasis.io',
    dexScreener: 'https://dexscreener.com/sapphire',
    subgraph: 'https://api.thegraph.com/subgraphs/name/valleyswap/exchange',
    defaultDex: 'ValleySwap',
    moralisChain: 'sapphire'
},
'2046399126': {
    name: 'ElysiumChain',
    nativeCurrency: 'LAVA',
    icon: 'https://assets.coingecko.com/coins/images/31314/large/lava.png',
    explorer: 'https://explorer.elysiumchain.tech',
    dexScreener: 'https://dexscreener.com/elysium',
    subgraph: 'https://api.thegraph.com/subgraphs/name/elysiumswap/exchange',
    defaultDex: 'ElysiumSwap',
    moralisChain: 'elysium'
},
'1030': {
    name: 'Concordium',
    nativeCurrency: 'CCD',
    icon: 'https://assets.coingecko.com/coins/images/14933/large/ccd.png',
    explorer: 'https://ccdscan.io',
    dexScreener: 'https://dexscreener.com/concordium',
    subgraph: 'https://api.thegraph.com/subgraphs/name/concordswap/exchange',
    defaultDex: 'ConcordSwap',
    moralisChain: 'concordium'
},
'7000': {
    name: 'ZetaChain',
    nativeCurrency: 'ZETA',
    icon: 'https://assets.coingecko.com/coins/images/30826/large/zeta_chain.png',
    explorer: 'https://explorer.zetachain.com',
    dexScreener: 'https://dexscreener.com/zetachain',
    subgraph: 'https://api.studio.thegraph.com/query/45376/zetaswap/v0.0.1',
    defaultDex: 'ZetaSwap',
    moralisChain: 'zetachain'
},
'81457': {
    name: 'Blast',
    nativeCurrency: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/34194/large/blast-icon.png',
    explorer: 'https://blastscan.io',
    dexScreener: 'https://dexscreener.com/blast',
    subgraph: 'https://api.studio.thegraph.com/query/45376/blastswap/v0.0.1',
    defaultDex: 'BlastSwap',
    moralisChain: 'blast'
},
'2611': {
    name: 'RedLight Chain',
    nativeCurrency: 'REDLC',
    icon: 'https://assets.coingecko.com/coins/images/30873/large/redlight.png',
    explorer: 'https://redlightscan.io',
    dexScreener: 'https://dexscreener.com/redlight',
    subgraph: 'https://api.thegraph.com/subgraphs/name/redlightswap/exchange',
    defaultDex: 'RedLightSwap',
    moralisChain: 'redlight'
},
'1201': {
    name: 'Polygon CDK',
    nativeCurrency: 'MATIC',
    icon: 'https://assets.coingecko.com/coins/images/4713/large/polygon.png',
    explorer: 'https://scan.polygon-cdk.io',
    dexScreener: 'https://dexscreener.com/polygon-cdk',
    subgraph: 'https://api.studio.thegraph.com/query/45376/cdkswap/v0.0.1',
    defaultDex: 'CDKSwap',
    moralisChain: 'polygon_cdk'
},
'4200': {
    name: 'Merlin Chain',
    nativeCurrency: 'BTC',
    icon: 'https://assets.coingecko.com/coins/images/31671/large/merlin.png',
    explorer: 'https://scan.merlinchain.io',
    dexScreener: 'https://dexscreener.com/merlin',
    subgraph: 'https://api.thegraph.com/subgraphs/name/merlinswap/exchange',
    defaultDex: 'MerlinSwap',
    moralisChain: 'merlin'
},
'37': {
    name: 'XPLA',
    nativeCurrency: 'XPLA',
    icon: 'https://assets.coingecko.com/coins/images/28362/large/xpla.png',
    explorer: 'https://explorer.xpla.io',
    dexScreener: 'https://dexscreener.com/xpla',
    subgraph: 'https://api.thegraph.com/subgraphs/name/xplaswap/exchange',
    defaultDex: 'XPLASwap',
    moralisChain: 'xpla'
},
'2000': {
    name: 'Dogechain',
    nativeCurrency: 'DOGE',
    icon: 'https://assets.coingecko.com/coins/images/26179/large/dogechain.jpeg',
    explorer: 'https://explorer.dogechain.dog',
    dexScreener: 'https://dexscreener.com/dogechain',
    subgraph: 'https://api.thegraph.com/subgraphs/name/dogeswap/exchange',
    defaultDex: 'DogeSwap',
    moralisChain: 'dogechain'
},
'10001': {
    name: 'EthereumPoW',
    nativeCurrency: 'ETHW',
    icon: 'https://assets.coingecko.com/coins/images/26997/large/logo-clear.png',
    explorer: 'https://www.oklink.com/en/ethw',
    dexScreener: 'https://dexscreener.com/ethw',
    subgraph: 'https://api.thegraph.com/subgraphs/name/uniwswap/exchange',
    defaultDex: 'UniWSwap',
    moralisChain: 'ethw'
},
'255': {
    name: 'Kroma',
    nativeCurrency: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/31493/large/kroma.png',
    explorer: 'https://kromascan.com',
    dexScreener: 'https://dexscreener.com/kroma',
    subgraph: 'https://api.studio.thegraph.com/query/45376/kromaswap/v0.0.1',
    defaultDex: 'KromaSwap',
    moralisChain: 'kroma'
},
'1101': {
    name: 'Polygon zkEVM',
    nativeCurrency: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/27887/large/zkevm.png',
    explorer: 'https://zkevm.polygonscan.com',
    dexScreener: 'https://dexscreener.com/polygon-zkevm',
    subgraph: 'https://api.studio.thegraph.com/query/45376/quickswap-v3-zkevm/v0.0.1',
    defaultDex: 'QuickSwap',
    moralisChain: 'polygon_zkevm'
},
'1440001': {
    name: 'XDC Network',
    nativeCurrency: 'XDC',
    icon: 'https://assets.coingecko.com/coins/images/2912/large/xdc.png',
    explorer: 'https://xdcscan.io',
    dexScreener: 'https://dexscreener.com/xdc',
    subgraph: 'https://api.thegraph.com/subgraphs/name/xswap/exchange',
    defaultDex: 'XSwap',
    moralisChain: 'xdc'
},
'7668': {
    name: 'MEVerse',
    nativeCurrency: 'MEV',
    icon: 'https://assets.coingecko.com/coins/images/29503/large/meverse.png',
    explorer: 'https://www.meversescan.io',
    dexScreener: 'https://dexscreener.com/meverse',
    subgraph: 'https://api.thegraph.com/subgraphs/name/meverseswap/exchange',
    defaultDex: 'MEVerseSwap',
    moralisChain: 'meverse'
},
'356256156': {
    name: 'Gather Network',
    nativeCurrency: 'GTH',
    icon: 'https://assets.coingecko.com/coins/images/12458/large/gather.png',
    explorer: 'https://explorer.gather.network',
    dexScreener: 'https://dexscreener.com/gather',
    subgraph: 'https://api.thegraph.com/subgraphs/name/gatherswap/exchange',
    defaultDex: 'GatherSwap',
    moralisChain: 'gather'
},
'888': {
    name: 'Wanchain',
    nativeCurrency: 'WAN',
    icon: 'https://assets.coingecko.com/coins/images/1763/large/wanchain.png',
    explorer: 'https://www.wanscan.org',
    dexScreener: 'https://dexscreener.com/wanchain',
    subgraph: 'https://api.thegraph.com/subgraphs/name/wanswap/exchange',
    defaultDex: 'WanSwap',
    moralisChain: 'wanchain'
},
'534352': {
    name: 'Scroll',
    nativeCurrency: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/30614/large/scroll.png',
    explorer: 'https://scrollscan.com',
    dexScreener: 'https://dexscreener.com/scroll',
    subgraph: 'https://api.studio.thegraph.com/query/45376/scrollswap/v0.0.1',
    defaultDex: 'ScrollSwap',
    moralisChain: 'scroll'
},
'169': {
    name: 'Manta Network',
    nativeCurrency: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/30801/large/token-logo_200.png',
    explorer: 'https://pacific-explorer.manta.network',
    dexScreener: 'https://dexscreener.com/manta',
    subgraph: 'https://api.studio.thegraph.com/query/45376/mantaswap/v0.0.1',
    defaultDex: 'MantaSwap',
    moralisChain: 'manta'
},
'713100': {
    name: 'Sei Network',
    nativeCurrency: 'SEI',
    icon: 'https://assets.coingecko.com/coins/images/28205/large/sei.png',
    explorer: 'https://sei.explorers.guru',
    dexScreener: 'https://dexscreener.com/sei',
    subgraph: 'https://api.studio.thegraph.com/query/45376/seiswap/v0.0.1',
    defaultDex: 'SeiSwap',
    moralisChain: 'sei'
},
'22776': {
    name: 'MAP Protocol',
    nativeCurrency: 'MAPO',
    icon: 'https://assets.coingecko.com/coins/images/6972/large/map.png',
    explorer: 'https://maposcan.io',
    dexScreener: 'https://dexscreener.com/map',
    subgraph: 'https://api.studio.thegraph.com/query/45376/mapswap/v0.0.1',
    defaultDex: 'MapSwap',
    moralisChain: 'map'
},
'7332': {
    name: 'TON Chain',
    nativeCurrency: 'TON',
    icon: 'https://assets.coingecko.com/coins/images/17980/large/ton.PNG',
    explorer: 'https://tonscan.org',
    dexScreener: 'https://dexscreener.com/ton',
    subgraph: 'https://api.studio.thegraph.com/query/45376/tonswap/v0.0.1',
    defaultDex: 'DeDust',
    moralisChain: 'ton'
},
'1111': {
    name: 'Injective',
    nativeCurrency: 'INJ',
    icon: 'https://assets.coingecko.com/coins/images/12882/large/Secondary_Symbol.png',
    explorer: 'https://explorer.injective.network',
    dexScreener: 'https://dexscreener.com/injective',
    subgraph: 'https://api.studio.thegraph.com/query/45376/injectiveswap/v0.0.1',
    defaultDex: 'Astroport',
    moralisChain: 'injective'
},
'2999': {
    name: 'Carbon Chain',
    nativeCurrency: 'SWTH',
    icon: 'https://assets.coingecko.com/coins/images/11891/large/Carbon.png',
    explorer: 'https://scan.carbon.network',
    dexScreener: 'https://dexscreener.com/carbon',
    subgraph: 'https://api.studio.thegraph.com/query/45376/carbonswap/v0.0.1',
    defaultDex: 'CarbonSwap',
    moralisChain: 'carbon'
},
'3125': {
    name: 'Kujira',
    nativeCurrency: 'KUJI',
    icon: 'https://assets.coingecko.com/coins/images/21623/large/kuji.png',
    explorer: 'https://finder.kujira.app',
    dexScreener: 'https://dexscreener.com/kujira',
    subgraph: 'https://api.studio.thegraph.com/query/45376/kujiraswap/v0.0.1',
    defaultDex: 'Fin',
    moralisChain: 'kujira'
},
'314': {
    name: 'Filecoin',
    nativeCurrency: 'FIL',
    icon: 'https://assets.coingecko.com/coins/images/12817/large/filecoin.png',
    explorer: 'https://filfox.info',
    dexScreener: 'https://dexscreener.com/filecoin',
    subgraph: 'https://api.studio.thegraph.com/query/45376/filswap/v0.0.1',
    defaultDex: 'FilSwap',
    moralisChain: 'filecoin'
},
}



// API configuration and market data fetching.



const TOKEN_CATEGORIES = {
  NATIVE: 'native',
  STABLE: 'stable',
  DEFI: 'defi',
  MEME: 'meme',
  NFT: 'nft',
  GAMING: 'gaming',
  OTHER: 'other'
};

const TIMEFRAME_OPTIONS = [
  { label: '24H', value: '24H', seconds: 86400 }
];


const API_CONFIG = {
  RETRY_ATTEMPTS: 3,
  RETRY_DELAY: 1000,
  BATCH_SIZE: 20,
  CACHE_DURATION: 5 * 60 * 1000,
  BALANCE_THRESHOLD: 0.01,
  REFRESH_INTERVAL: 60000,
  CORS_PROXY: 'https://cors-anywhere.herokuapp.com/',
  COINGECKO_BASE_URL: 'https://api.coingecko.com/api/v3'
};

// Updated market data fetching function
const fetchMarketData = async (timeframe) => {
  try {
    const days = timeframe === '24H' ? 1 : 0; // Default to 0 if timeframe is not recognized

    // Use more granular intervals for shorter timeframes
    const interval = timeframe === '24H' ? '60minute' : null;

    if (days === 0 || !interval) {
      console.error("Invalid timeframe provided");
      return; // Exit the function for unsupported timeframes
    }

    const response = await axios.get(`${API_CONFIG.COINGECKO_BASE_URL}/coins/bitcoin/market_chart`, {
      params: {
        vs_currency: 'usd',
        days: days,
        interval: interval
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching market data:", error);
  }
};


  

// Update market stats function to get current BTC data
const fetchMarketStats = async () => {
  try {
    const response = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
      params: {
        ids: 'bitcoin',
        vs_currencies: 'usd',
        include_24hr_change: true
      }
    });

    const btcData = response.data.bitcoin;
    return {
      price: btcData.usd,
      change24h: btcData.usd_24h_change || 0
    };
  } catch (error) {
    console.error('Error fetching BTC stats:', error);
    return {
      price: 0,
      change24h: 0
    };
  }
};




// Add dummy data generator
// Add dummy data generator
const generateDummyChartData = (timeframe) => {
  const now = Date.now() / 1000;
  const days = timeframe === '24H' ? 1 : 0; // Default to 0 if timeframe is not recognized
  
  const points = [];
  const intervals = days * 24; // hourly points
  const startPrice = 40000; // example BTC price

  if (days === 0) {
    console.error("Invalid timeframe provided");
    return points; // Return empty points for invalid timeframes
  }

  for (let i = 0; i < intervals; i++) {
    const timestamp = now - (days * 86400) + (i * 3600);
    const randomChange = (Math.random() - 0.5) * 200;
    points.push({
      timestamp,
      value: startPrice + randomChange,
    });
  }

  return points;
};



// Part 2: State management and connection handling.



const dataCache = {
  data: {},
  timestamp: {},
  CACHE_DURATION: 5 * 60 * 1000 // 5 minutes
};

const useTokenCategories = () => {
  const categorizeToken = (token) => {
    if (token.isNative) return TOKEN_CATEGORIES.NATIVE;
    if (token.symbol?.toLowerCase().includes('usd')) return TOKEN_CATEGORIES.STABLE;
    if (isDefiToken(token)) return TOKEN_CATEGORIES.DEFI;
    if (isMemeToken(token)) return TOKEN_CATEGORIES.MEME;
    if (isGamingToken(token)) return TOKEN_CATEGORIES.GAMING;
    return TOKEN_CATEGORIES.OTHER;
  };

  const isDefiToken = (token) => {
    const defiKeywords = ['swap', 'yield', 'dao', 'stake', 'farm', 'lend'];
    return defiKeywords.some(keyword => 
      token.name?.toLowerCase().includes(keyword) || 
      token.symbol?.toLowerCase().includes(keyword)
    );
  };

  const isMemeToken = (token) => {
    const memeKeywords = ['pepe', 'doge', 'shib', 'inu', 'moon', 'elon', 'safe', 'baby'];
    return memeKeywords.some(keyword => 
      token.name?.toLowerCase().includes(keyword) || 
      token.symbol?.toLowerCase().includes(keyword)
    );
  };

  const isGamingToken = (token) => {
    const gamingKeywords = ['play', 'game', 'meta', 'verse', 'world', 'land'];
    return gamingKeywords.some(keyword => 
      token.name?.toLowerCase().includes(keyword) || 
      token.symbol?.toLowerCase().includes(keyword)
    );
  };

  return { categorizeToken };
};

const WalletAnalytics = () => {
  // State Management
  const [searchAddress, setSearchAddress] = useState('');
  const [walletData, setWalletData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState({});
  const [timeframe, setTimeframe] = useState('24H');
  const [selectedChain, setSelectedChain] = useState(null);
  const [activeCategory, setActiveCategory] = useState('all');
  const [marketHistory, setMarketHistory] = useState([]);
  const [marketStats, setMarketStats] = useState({
    price: 0,
    change24h: 0,

  });

  // Chart data fetching function
  const fetchChartData = async (tf) => {
    const cacheKey = `btc-${tf}`;
    const now = Date.now();
  
    // Check cache first
    if (
      dataCache.data[cacheKey] &&
      dataCache.timestamp[cacheKey] &&
      now - dataCache.timestamp[cacheKey] < dataCache.CACHE_DURATION
    ) {
      setMarketHistory(dataCache.data[cacheKey]);
      return;
    }
  
    try {
      const days = 1; // Fixed to 24H
      const interval = 'minute'; // Fixed interval for 24H data
  
      const response = await axios.get('https://api.coingecko.com/api/v3/coins/bitcoin/market_chart', {
        params: {
          vs_currency: 'usd',
          days: days,
          interval: interval
        }
      });
  
      if (!response.data?.prices) {
        throw new Error('Invalid market data response');
      }
  
      const processedData = response.data.prices.map(([timestamp, price]) => ({
        timestamp: new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        value: parseFloat(price.toFixed(2))
      }));
  
      // Update cache
      dataCache.data[cacheKey] = processedData;
      dataCache.timestamp[cacheKey] = now;
  
      setMarketHistory(processedData);
    } catch (error) {
      console.error('Error fetching BTC data:', error);
      // Use dummy data if there's an error
      setMarketHistory(generateDummyChartData(tf));
    }
  };

  useEffect(() => {
    fetchChartData(timeframe);
  }, [timeframe]);

  const [totalValue, setTotalValue] = useState(0);
  const [expandedChains, setExpandedChains] = useState({});
  const [selectedToken, setSelectedToken] = useState(null);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [error, setError] = useState(null);
  const [isModalActive, setIsModalActive] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const modalRef = useRef(null);
  const activeRowRef = useRef(null);
  const [showBackdrop, setShowBackdrop] = useState(false);

  // Hooks
  const { address: account } = useAccount();
  const chainId = useChainId();
  const { categorizeToken } = useTokenCategories();


useEffect(() => {
  const loadData = async () => {
    try {
      console.log('Fetching BTC data...');
      const btcResponse = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
        params: {
          ids: 'bitcoin',
          vs_currencies: 'usd',
          include_24hr_change: true
        }
      });
      
      if (btcResponse.data?.bitcoin) {
        setMarketStats({
          price: btcResponse.data.bitcoin.usd,
          change24h: btcResponse.data.bitcoin.usd_24h_change || 0
        });
      }

      const chartResponse = await axios.get('https://api.coingecko.com/api/v3/coins/bitcoin/market_chart', {
        params: {
          vs_currency: 'usd',
          days: 1, // Always 1 for 24H timeframe
          interval: '60minute' // Fixed interval for 24H
        }
      });

      if (chartResponse.data?.prices) {
        const chartData = chartResponse.data.prices.map(([timestamp, price]) => ({
          timestamp: timestamp / 1000,
          value: price
        }));
        setMarketHistory(chartData);
      }
    } catch (error) {
      console.error('Error loading BTC data:', error);
    }
  };

  loadData();
  const interval = setInterval(loadData, 60000);
  return () => clearInterval(interval);
}, [timeframe]);

// Effect for wallet connection
useEffect(() => {
  if (account && !walletData) {
    fetchWalletData(account);
  }
}, [account, walletData]);

const handleManualRefresh = async () => {
  setLoading(true);
  try {
    if (account) {
      await fetchWalletData(account);
    }
    const btcResponse = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
      params: {
        ids: 'bitcoin',
        vs_currencies: 'usd',
        include_24hr_change: true
      }
    });
    
    if (btcResponse.data?.bitcoin) {
      setMarketStats({
        price: btcResponse.data.bitcoin.usd,
        change24h: btcResponse.data.bitcoin.usd_24h_change || 0
      });
    }

    const chartResponse = await axios.get('https://api.coingecko.com/api/v3/coins/bitcoin/market_chart', {
      params: {
        vs_currency: 'usd',
        days: 1, // Always 1 for 24H timeframe
        interval: '5minute' // Fixed interval for 24H
      }
    });
    

    if (chartResponse.data?.prices) {
      setMarketHistory(chartResponse.data.prices.map(([timestamp, price]) => ({
        timestamp: timestamp / 1000,
        value: price
      })));
    }
  } catch (error) {

  }
};

const handleTimeframeChange = async (newTimeframe) => {
  setTimeframe(newTimeframe);
  localStorage.setItem('savedTimeframe', newTimeframe); // Save selected timeframe to localStorage


  
  try {
    const response = await axios.get('https://api.coingecko.com/api/v3/coins/bitcoin/market_chart', {
      params: {
        vs_currency: 'usd',
        days: 1, // Always 1 for 24H timeframe
        interval: '5minute' // Fixed interval for 24H
      }
    });

    if (response.data?.prices) {
      setMarketHistory(response.data.prices.map(([timestamp, price]) => ({
        timestamp: timestamp / 1000,
        value: price
      })));
    }
  } catch (error) {
    console.error('Error changing timeframe:', error);
  }
};



  // Part 3: Handlers and data fetching logic.


  const toggleChain = (chainId) => {
    setExpandedChains(prev => ({
      ...prev,
      [chainId]: !prev[chainId]
    }));
  };

  const handleTokenClick = (token, event) => {
    if (!event?.currentTarget || !token) return;
    
    event.stopPropagation();
    
    setSelectedToken(token);
    setShowBackdrop(true);
    setIsModalActive(true);
    
    const rect = event.currentTarget.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    
    const modalWidth = 250;
    const modalHeight = 200;
    
    let xPosition = rect.right + 10;
    let yPosition = rect.top;
  
    if (xPosition + modalWidth > viewportWidth) {
      xPosition = Math.max(10, rect.left - modalWidth - 10);
    }
  
    yPosition = Math.max(10, Math.min(yPosition, viewportHeight - modalHeight - 10));
    yPosition += window.scrollY;
  
    setModalPosition({ x: xPosition, y: yPosition });
    setIsModalActive(true);
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    setIsModalActive(false);
    setShowBackdrop(false);
    setSelectedToken(null);
  };

  // Utility functions
  const retryOperation = async (operation, attempts = API_CONFIG.RETRY_ATTEMPTS) => {
    for (let i = 0; i < attempts; i++) {
      try {
        return await operation();
      } catch (error) {
        if (i === attempts - 1) throw error;
        await new Promise(resolve => setTimeout(resolve, API_CONFIG.RETRY_DELAY * (i + 1)));
      }
    }
  };

  const updateLoadingProgress = (chainId, status, message = '') => {
    setLoadingProgress(prev => ({
      ...prev,
      [chainId]: { status, message }
    }));
  };

  // Enhanced token price data fetching
  const fetchTokenPriceData = async (tokenAddress, chainId) => {
    try {
      // Try Covalent first
      try {
        const covalentPrice = await retryOperation(async () => {
          const response = await axios.get(
            `https://api.covalenthq.com/v1/pricing/historical_by_addresses_v2/${chainId}/${tokenAddress}/`,
            {
              headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_COVALENT_API_KEY}`
              }
            }
          );

          if (!response.data?.data?.[0]?.prices?.[0]) {
            throw new Error('Invalid Covalent price data');
          }

          return response.data.data[0].prices[0];
        });

        return {
          price: covalentPrice.price,
          priceChange24h: covalentPrice.price_change_24h,
          volume24h: covalentPrice.volume_24h,
        };
      } catch (covalentError) {
        console.warn('Covalent price fetch failed, trying Moralis:', covalentError);
        
        // Fallback to Moralis
        const moralisPrice = await retryOperation(async () => {
          const response = await axios.get(
            `https://deep-index.moralis.io/api/v2/erc20/${tokenAddress}/price`,
            {
              headers: {
                'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY
              },
              params: {
                chain: SUPPORTED_NETWORKS[chainId].moralisChain
              }
            }
          );

          if (!response.data?.usdPrice) {
            throw new Error('Invalid Moralis price data');
          }

          return {
            price: response.data.usdPrice,
            priceChange24h: 0,
            volume24h: 0,
          };
        });

        return moralisPrice;
      }
    } catch (error) {
      console.error('Error fetching token price:', error);
      // Return default values if both APIs fail
      return {
        price: 0,
        priceChange24h: 0,
        volume24h: 0,
      };
    }
  };

  // Enhanced Covalent balances fetching
  const fetchCovalentBalances = async (address, chainId) => {
    try {
      updateLoadingProgress(chainId, 'loading', 'Fetching Covalent data...');
      
      const response = await axios.get(
        `https://api.covalenthq.com/v1/${chainId}/address/${address}/balances_v2/`,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_COVALENT_API_KEY}`
          }
        }
      );

      if (!response.data?.data?.items) {
        throw new Error('Invalid Covalent balance data');
      }

      const tokens = [];
      let totalChainValue = 0;

      for (const item of response.data.data.items) {
        if (parseFloat(item.balance) > 0) {
          const balance = parseFloat(item.balance) / Math.pow(10, item.contract_decimals);
          const valueUSD = parseFloat(item.quote) || 0;

          if (valueUSD >= API_CONFIG.BALANCE_THRESHOLD) {
            const tokenData = {
              chainId,
              address: item.contract_address,
              symbol: item.contract_ticker_symbol,
              name: item.contract_name,
              balance: balance.toFixed(6),
              valueUSD,
              price: parseFloat(item.quote_rate) || 0,
              priceChange24h: item.quote_rate_24h_percent_change || 0,
              logo: item.logo_url || '/default-token.png',
              isNative: item.native_token,
              explorer: `${SUPPORTED_NETWORKS[chainId].explorer}/token/${item.contract_address}`,
              dexScreener: `${SUPPORTED_NETWORKS[chainId].dexScreener}/token/${item.contract_address}`
            };

            tokenData.category = categorizeToken(tokenData);
            tokens.push(tokenData);
            totalChainValue += valueUSD;
          }
        }
      }

      updateLoadingProgress(chainId, 'success', 'Data fetched successfully');
      return { tokens, totalChainValue };

    } catch (error) {
      console.error('Covalent fetch error:', error);
      updateLoadingProgress(chainId, 'error', 'Covalent fetch failed');
      throw error;
    }
  };



  // Part 4: Data fetching and processing logic.



  // Enhanced Moralis balances fetching
  const fetchMoralisBalances = async (address, chainId) => {
    try {
      updateLoadingProgress(chainId, 'loading', 'Fetching Moralis data...');
      
      const response = await axios.get(
        `https://deep-index.moralis.io/api/v2/${address}/erc20`,
        {
          headers: {
            'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY
          },
          params: {
            chain: SUPPORTED_NETWORKS[chainId].moralisChain
          }
        }
      );

      const tokens = [];
      let totalChainValue = 0;

      for (const token of response.data) {
        const balance = parseFloat(token.balance) / Math.pow(10, parseInt(token.decimals));
        if (balance > 0) {
          const priceData = await fetchTokenPriceData(token.token_address, chainId);
          
          if (priceData) {
            const valueUSD = balance * priceData.price;
            
            if (valueUSD >= API_CONFIG.BALANCE_THRESHOLD) {
              const tokenData = {
                chainId,
                address: token.token_address,
                symbol: token.symbol,
                name: token.name,
                balance: balance.toFixed(6),
                valueUSD,
                price: priceData.price,
                priceChange24h: priceData.priceChange24h,
                logo: token.thumbnail || '/default-token.png',
                explorer: `${SUPPORTED_NETWORKS[chainId].explorer}/token/${token.token_address}`,
                dexScreener: `${SUPPORTED_NETWORKS[chainId].dexScreener}/token/${token.token_address}`
              };

              tokenData.category = categorizeToken(tokenData);
              tokens.push(tokenData);
              totalChainValue += valueUSD;
            }
          }
        }
      }

      updateLoadingProgress(chainId, 'success', 'Data fetched successfully');
      return { tokens, totalChainValue };

    } catch (error) {
      console.error('Moralis fetch error:', error);
      updateLoadingProgress(chainId, 'error', 'Moralis fetch failed');
      throw error;
    }
  };

  // Enhanced chain data fetching
  const fetchChainData = async (address, chainId) => {
    try {
      // First try Covalent
      try {
        const covalentData = await fetchCovalentBalances(address, chainId);
        return {
          chainId,
          chainName: SUPPORTED_NETWORKS[chainId].name,
          icon: SUPPORTED_NETWORKS[chainId].icon,
          tokens: covalentData.tokens,
          totalValue: covalentData.totalChainValue,
          nativeCurrency: SUPPORTED_NETWORKS[chainId].nativeCurrency,
          explorer: SUPPORTED_NETWORKS[chainId].explorer
        };
      } catch (covalentError) {
        console.warn('Covalent fetch failed, trying Moralis:', covalentError);
        
        // Fallback to Moralis
        const moralisData = await fetchMoralisBalances(address, chainId);
        return {
          chainId,
          chainName: SUPPORTED_NETWORKS[chainId].name,
          icon: SUPPORTED_NETWORKS[chainId].icon,
          tokens: moralisData.tokens,
          totalValue: moralisData.totalChainValue,
          nativeCurrency: SUPPORTED_NETWORKS[chainId].nativeCurrency,
          explorer: SUPPORTED_NETWORKS[chainId].explorer
        };
      }
    } catch (error) {
      console.error(`Error fetching chain ${chainId} data:`, error);
      // Return empty chain data if both APIs fail
      return {
        chainId,
        chainName: SUPPORTED_NETWORKS[chainId].name,
        icon: SUPPORTED_NETWORKS[chainId].icon,
        tokens: [],
        totalValue: 0,
        nativeCurrency: SUPPORTED_NETWORKS[chainId].nativeCurrency,
        explorer: SUPPORTED_NETWORKS[chainId].explorer
      };
    }
  };

  // Main wallet data fetching function
  const fetchWalletData = async (address) => {
    if (!address) return;
    setLoading(true);
    setError(null);

    try {
      const web3 = getWeb3(chainId);
      if (!web3.utils.isAddress(address)) {
        throw new Error('Invalid wallet address');
      }

      // Fetch all chain data in parallel
      const chainPromises = Object.keys(SUPPORTED_NETWORKS).map(chainId => 
        fetchChainData(address, chainId)
      );

      const chainsData = await Promise.all(chainPromises);
      const activeChains = chainsData.filter(chain => 
        chain.tokens.length > 0
      );

      const totalPortfolioValue = activeChains.reduce((sum, chain) => {
        const chainValue = chain.tokens.reduce((acc, token) => {
          return acc + (parseFloat(token.valueUSD) || 0);
        }, 0);
        return sum + chainValue;
      }, 0);

      // Update wallet data
      setWalletData({
        address,
        chains: activeChains,
        totalValue: totalPortfolioValue,
        lastUpdated: new Date().toISOString()
      });
      setTotalValue(totalPortfolioValue);

      // Auto-expand first chain if there are active chains
      if (activeChains.length > 0 && Object.keys(expandedChains).length === 0) {
        setExpandedChains({ [activeChains[0].chainId]: false });
      }

    } catch (error) {
      console.error('Error fetching wallet data:', error);
      setError(error.message);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };




  // Part 5: Rendering logic and UI components.



  // Chain section renderer
 const renderChainSection = (chainData) => {
  if (!chainData) return null;
  
  const isExpanded = expandedChains[chainData.chainId];

  return (
    <div key={chainData.chainId} className="chain-card">
      <div className="chain-header" onClick={() => toggleChain(chainData.chainId)}>
        <div className="chain-info">
          <img 
            src={chainData.icon} 
            alt={chainData.chainName} 
            className="chain-icon"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/default-token.png';
            }}
          />
          <div className="chain-details">
            <h3>{chainData.chainName}</h3>
            <div className="chain-stats">
              <span>{chainData.tokens?.length || 0} tokens</span>
            </div>
          </div>
        </div>
        <div className="chain-value">
          ${(chainData.totalValue || 0).toLocaleString(undefined, { maximumFractionDigits: 2 })}
        </div>
      </div>

      {isExpanded && (
        <div className="chain-content">
          <div className="token-filters">
            <button 
              className={`filter-btn ${activeCategory === 'all' ? 'active' : ''}`}
              onClick={() => setActiveCategory('all')}
            >
              All
            </button>
            {Object.values(TOKEN_CATEGORIES).map(category => (
              <button
                key={category}
                className={`filter-btn ${activeCategory === category ? 'active' : ''}`}
                onClick={() => setActiveCategory(category)}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
          </div>

          <div className="token-list">
            {chainData.tokens
              .filter(token => activeCategory === 'all' || token.category === activeCategory)
              .map((token, index) => (
                <div 
                  key={index} 
                  className="token-row"
                  onClick={(e) => handleTokenClick({ ...token, chainId: chainData.chainId }, e)}
                >
                  <div className="token-left">
                    <TokenImage 
                      logo={token.logo} 
                      alt={token.symbol} 
                      fallback={SUPPORTED_NETWORKS[chainData.chainId].icon}
                    />
                    <div className="token-info">
                      <div className="token-name-row">
                        <span className="token-symbol">{token.symbol}</span>
                        <span className="token-name">{token.name}</span>
                      </div>
                      <div className="token-balance">
                        {token.balance} {token.symbol}
                      </div>
                    </div>
                  </div>
                  <div className="token-right">
                    <div className="token-value">
                      ${token.valueUSD.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                    </div>
                    <div className="token-price">
                      ${token.price.toFixed(6)}
                    </div>
                    {token.priceChange24h && (
                      <div className={`token-change ${token.priceChange24h >= 0 ? 'positive' : 'negative'}`}>
                        {token.priceChange24h > 0 ? '+' : ''}{token.priceChange24h.toFixed(2)}%
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

// Main render
return (
  <div className="wallet-analytics-container">
    <div className="search-section">
      <input
        type="text"
        placeholder="Search by address (0x...)"
        value={searchAddress}
        onChange={(e) => setSearchAddress(e.target.value)}
        className="search-input"
      />
      <button 
        onClick={() => fetchWalletData(searchAddress)}
        className="search-button"
        disabled={loading}
      >
        {loading ? 'Searching...' : 'Search'}
      </button>
      {account && (
        <button 
          onClick={handleManualRefresh}
          className="refresh-button"
          disabled={loading}
        >
          Refresh
        </button>
      )}
    </div>

    {loading && (
      <div className="loading-overlay">
        <div className="loader"></div>
        <div className="loading-text">Loading wallet data...</div>
      </div>
    )}

    {!loading && error && (
      <div className="error-message">
        {error}
      </div>
    )}

<div className="portfolio-overview">
  <div className="portfolio-header">
    <div className="portfolio-value-container">
      <h2>Portfolio Value</h2>
      <div className="total-value">
        ${(walletData?.totalValue || 0).toLocaleString(undefined, { maximumFractionDigits: 2 })}
      </div>
      {/* Only show 24h change */}
      <div className="market-changes">
        {marketStats && (
          <span className={`change ${marketStats.change24h >= 0 ? 'positive' : 'negative'}`}>
            24h: {marketStats.change24h ? 
              `${marketStats.change24h > 0 ? '+' : ''}${marketStats.change24h.toFixed(2)}%` : 
              'Loading...'}
          </span>
        )}
      </div>
    </div>

    <div className="timeframe-selector">
      {TIMEFRAME_OPTIONS.map(tf => (
        <button
          key={tf.value}
          className={`timeframe-button ${timeframe === tf.value ? 'active' : ''}`}
          onClick={() => handleTimeframeChange(tf.value)}
        >
          {tf.label}
        </button>
      ))}
    </div>

    <div className="chart-container">
      {marketHistory.length > 0 ? (
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart 
            data={marketHistory}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#3461FF" stopOpacity={0.3} />
                <stop offset="95%" stopColor="#3461FF" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid 
              horizontal={true}
              vertical={false}
              strokeDasharray="3 3" 
              stroke="#2A2E5A" 
            />
          
            <YAxis hide={true} domain={['auto', 'auto']} />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#3461FF"
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#colorValue)"
            />
          </AreaChart>
        </ResponsiveContainer>
  ) : (
    <div style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      Loading market data...
    </div>
  )}
</div>
</div>

      {walletData?.chains && (
        <div className="chains-container">
          {walletData.chains
            .sort((a, b) => (b.totalValue || 0) - (a.totalValue || 0))
            .map(renderChainSection)}
        </div>
      )}
    </div>

    {!loading && !walletData && account && (
      <div className="empty-state">
        <div className="empty-state-content">
          <div className="empty-icon">🔍</div>
          <h3>No Wallet Data</h3>
          <p>Click Search to view your portfolio details</p>
        </div>
      </div>
    )}

    {selectedToken && (
      <>
        <div 
          className="modal-backdrop"
          onClick={handleBackdropClick}
        >
          <div 
            ref={modalRef}
            className={`token-stats-modal ${isModalActive ? 'active' : ''}`}
            onClick={(e) => e.stopPropagation()}
            style={{
              position: 'absolute',
              left: `${modalPosition.x}px`,
              top: `${modalPosition.y}px`
            }}
          >
            <div className="token-stats-header">
              <div className="header-content">
                <TokenImage
                  logo={selectedToken.logo}
                  alt={selectedToken.symbol}
                  fallback={SUPPORTED_NETWORKS[selectedToken.chainId]?.icon || '/default-token.png'}
                  className="token-stats-icon"
                />
                <div className="token-stats-title">
                  <h4>{selectedToken.symbol}</h4>
                  <span>{selectedToken.name}</span>
                </div>
              </div>
              <button 
                onClick={handleCloseModal}
                className="close-button"
                aria-label="Close"
              >
                ×
              </button>
            </div>
            <div className="token-stats-content">
              <div className="token-stat-row">
                <span>Price:</span>
                <span>${selectedToken.price.toFixed(6)}</span>
              </div>
              {selectedToken.priceChange24h && (
                <div className="token-stat-row">
                  <span>24h Change:</span>
                  <span className={selectedToken.priceChange24h >= 0 ? 'positive' : 'negative'}>
                    {selectedToken.priceChange24h > 0 ? '+' : ''}{selectedToken.priceChange24h.toFixed(2)}%
                  </span>
                </div>
              )}
              <div className="token-stat-links">
                <a 
                  href={selectedToken.explorer} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(selectedToken.explorer, '_blank');
                    handleCloseModal(e);
                  }}
                >
                  Explorer
                </a>
                <a 
                  href={selectedToken.dexScreener} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(selectedToken.dexScreener, '_blank');
                    handleCloseModal(e);
                  }}
                >
                 DEX Info
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    )}
  </div>
);
}

export default WalletAnalytics;