import React, { useState } from "react";
import { Link } from "react-router-dom";
import templateImg from "../images/template.jpg";
import bannerTemplate from "../images/template_banner.png";
import discordIcon from "../images/discord.svg";
import telegramIcon from "../images/telegram.svg";
import websiteIcon from "../images/website.svg";
import twitterIcon from "../images/x.svg";
import approvedIcon from "../images/approved-icon-1.png";
import notApprovedIcon from "../images/not-approved-icon-3.png";
import defaultIcon from "../images/DiamondDefaultIcon.svg";
import selectedIcon from "../images/DiamondSelectedIcon.svg";
import baseIcon from "../images/base.png";
import bnbIcon from "../images/bnb.png";
import lineaIcon from "../images/linea.png";
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function BondingCard({ chainId, rowdata, index, cs }) {
  const isMobile = useMediaQuery('(max-width:768px)');
  let details = rowdata.poolDetails.toString().split("$#$");
  const social = {
    logourl: details[0],
    bannerurl: details[1],
    website: details[2],
    blockstar: details[3],
    facebook: details[4],
    twitter: details[5],
    github: details[6],
    telegram: details[7],
    instagram: details[8],
    discord: details[9],
    reddit: details[10],
    youtube: details[11],
    brief: details[12],
  };
  
  const [imgageURL, setImageURL] = React.useState(social.logourl);
  const [bannerURL, setBannerURL] = React.useState(social.bannerurl);
  const [isFavorited, setIsFavorited] = useState(() => {
    const pumpFavoriteAddresses = JSON.parse(localStorage.getItem('pumpFavorites')) || [];
    return pumpFavoriteAddresses.includes(rowdata.poolAddress);
  });

  const setimage = () => setImageURL(templateImg);
  const setBannerImage = () => setBannerURL(bannerTemplate);

  const toggleFavorite = () => {
    const pumpFavoriteAddresses = JSON.parse(localStorage.getItem('pumpFavorites')) || [];
    if (isFavorited) {
      const updatedFavorites = pumpFavoriteAddresses.filter(address => address !== rowdata.poolAddress);
      localStorage.setItem('pumpFavorites', JSON.stringify(updatedFavorites));
    } else {
      pumpFavoriteAddresses.push(rowdata.poolAddress);
      localStorage.setItem('pumpFavorites', JSON.stringify(pumpFavoriteAddresses));
    }
    setIsFavorited(!isFavorited);
  };

  const getNetworkIcon = () => {
    if (rowdata.network === "Base" || rowdata.chainId === "base") {
      return baseIcon;
    } else if (rowdata.network === "BNB" || rowdata.chainId === "bnb") {
      return bnbIcon;
    } else if (rowdata.network === "Linea" || rowdata.chainId === "linea") {
      return lineaIcon;
    } else {
      return baseIcon;
    }
  };

  const statusText = rowdata.poolState === '0' ? 'Active' : rowdata.poolState === '1' ? 'Cancelled' : 'Finished';
  
  // Calculate progress percentage based on market cap
  const MAX_MARKET_CAP = 69000; // $69,000 = 100%
  const calculateProgress = () => {
    const marketCap = parseFloat(rowdata.currentMK) || 0;
    const percentage = (marketCap / MAX_MARKET_CAP) * 100;
    return Math.min(Math.max(percentage, 0), 100); // Clamp between 0 and 100
  };

  const progressPercentage = calculateProgress();
  const progressBarColor = "#1CBC23"; // Always green for the filled portion
  const baseColor = "#621CBC"; // Purple base color

  const isNameTooLong = rowdata.name && rowdata.name.length > 13;
  const displayText = isNameTooLong ? `$${rowdata.symbol}` : rowdata.name;

  return (
    <div
      className={`col-12 col-sx-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 item explore-item mt-2 project-card-item`}
      key={rowdata.poolAddress + rowdata.token}
      style={{ padding: '0px 3px', margin: '0px', marginTop: '0px' }}
    >
      <div style={{ padding: "1px", borderRadius: "8px", background: "linear-gradient(90deg, #6604FD 0%, #01F4A4 100%)", backgroundColor: "var(--bcgame-sk-woodsmoke, #111415)" }}>
        <div className="card project-card" style={{ borderRadius: "8px", backgroundColor: "var(--bcgame-sk-woodsmoke, #111415)", overflow: "hidden", width: "100%" }}>
          <div className="card-header" style={{ height: isMobile ? "120px" : "94px", borderRadius: "8px 8px 0 0", background: "", display: "flex", flexDirection: "column", overflow: "hidden", position: "relative", borderBottom: "none" }}>
            <div style={{ position: "absolute", top: "8px", left: "8px", backgroundColor: "var(--bcgame-sk-woodsmoke, #111415)", borderRadius: "13.3px", display: "flex", alignItems: "center", padding: "5px 10px" }}>
              <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "#1cbc23", boxShadow: "0 0 8px #1cbc23", marginRight: "5px" }}></div>
              <span style={{ color: "#FFFFFF", fontSize: "10px", fontWeight: "normal" }}>{statusText.toUpperCase()}</span>
            </div>
            <img src={bannerURL} alt="Banner" style={{ borderRadius: "8px 8px 0 0", objectFit: "cover", width: "100%", height: isMobile ? "120px" : "94px", borderBottom: "none" }} onError={() => setBannerImage()} />
            <img
              src={isFavorited ? selectedIcon : defaultIcon}
              alt="Favorite"
              style={{ position: "absolute", top: "8px", right: "8px", cursor: "pointer", width: "20px", height: "20px" }}
              onClick={toggleFavorite}
            />
          </div>
          <div className="card-body d-flex flex-column" style={{ padding: isMobile ? "15px" : "5px", width: "100%", borderRadius: "8px", flexGrow: 1 }}>
            <div className="d-flex justify-content-between pb-2" style={{ marginTop: "-8px" }}>
              <div className="d-flex" style={{ flexGrow: 1 }}>
                <div className="project-logo" style={{ paddingRight: "10px", marginTop: "10px", padding: "3px", display: "inline-block", borderRadius: "50%", background: "linear-gradient(180deg, #6604FD 0%, #01F4A4 100%)", width: "56px", height: "56px", boxSizing: "border-box" }}>
                  <div style={{ borderRadius: "50%", overflow: "hidden", width: "50px", height: "50px", backgroundColor: "var(--bcgame-sk-woodsmoke, #111415)" }}>
                    <img src={imgageURL} onError={() => setimage()} alt="logo" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "50%" }} />
                  </div>
                </div>
                <div>
                  <div>
                    <Link to={`/bonding-details/${rowdata.poolAddress}??chainid=${chainId}`}>
                      <h5 className="mx-0 mt-2 mb-0" style={{ paddingTop: "2px", paddingLeft: "10px", paddingBottom: "2px", fontSize: "18px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {displayText}
                      </h5>
                    </Link>
                  </div>
                  <div className="social-icons" style={{ marginTop: "2px", paddingLeft: "10px", paddingRight: "5px", flexGrow: 1 }}>
                    {social.website && (
                      <a target="_blank" rel="noreferrer" href={social.website}>
                        <img src={websiteIcon} alt="Website" style={{ width: "24px", marginRight: "5px" }} />
                      </a>
                    )}
                    {social.twitter && (
                      <a target="_blank" rel="noreferrer" href={social.twitter}>
                        <img src={twitterIcon} alt="Twitter" style={{ width: "24px", marginRight: "5px" }} />
                      </a>
                    )}
                    {social.discord && (
                      <a target="_blank" rel="noreferrer" href={social.discord}>
                        <img src={discordIcon} alt="Discord" style={{ width: "24px", marginRight: "5px" }} />
                      </a>
                    )}
                    {social.telegram && (
                      <a target="_blank" rel="noreferrer" href={social.telegram}>
                        <img src={telegramIcon} alt="Telegram" style={{ width: "24px", marginRight: "0px" }} />
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ backgroundColor: "#26283C", borderRadius: "8px", padding: "0px", display: "flex", alignItems: "center", justifyContent: "center", width: "30px", height: "30px", marginTop: "34px", marginRight: "5px", flexShrink: 0, flexGrow: 0 }}>
                <img src={getNetworkIcon()} alt="Network" style={{ width: "22px" }} />
              </div>
            </div>

            <div className="mt-auto" style={{ backgroundColor: "#0B0D21", borderRadius: "8px", padding: "4px 10px", color: "#FFFFFF", border: "1.66px solid rgba(255, 255, 255, 0.1)", marginBottom: "5px", marginTop: "4px" }}>
              <div style={{ marginBottom: "10px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                  <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "11px", fontWeight: "400", color: "#FFFFFF" }}>PROGRESS:</span>
                  <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "11px", fontWeight: "400", color: "#FFFFFF" }}>{progressPercentage.toFixed(2)}%</span>
                </div>
                <div style={{ height: "10px", borderRadius: "5px", backgroundColor: baseColor, overflow: "hidden" }}>
                  <div 
                    style={{ 
                      height: "100%", 
                      borderRadius: "5px", 
                      backgroundColor: progressBarColor, 
                      width: `${progressPercentage}%`,
                      transition: "width 0.5s ease-out"
                    }}
                  />
                </div>
              </div>
              <div style={{ marginBottom: "0px" }}>
                <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "11px", fontWeight: "400", color: "#FFFFFF" }}>MARKET CAP:</span>
                <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "11px", fontWeight: "400", color: "#FFFFFF", float: "right" }}>${rowdata.currentMK.toFixed(2)}</span>
              </div>
              <div>
                <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "11px", fontWeight: "400", color: "#FFFFFF" }}>HOLDERS:</span>
                <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "11px", fontWeight: "400", color: "#FFFFFF", float: "right" }}>{rowdata.holders?.length}</span>
              </div>
            </div>
            <div style={{ marginTop: "0px", marginBottom: "0", marginLeft: "2px", marginRight: "2px" }}>
              <Link to={`/bonding-details/${rowdata.poolAddress}??chainid=${chainId}`} className="btn btn-smaller w-100" style={{ background: "linear-gradient(180deg, #6600FF 0%, #20A595 100%)", borderRadius: "8px", color: "#FFFFFF", textAlign: "center", padding: "10px 0", fontFamily: "Quicksand, Helvetica", fontSize: "14px", fontWeight: "400" }}>
                VIEW SALE
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}